import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity, Linking } from 'react-native';

import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function WomenInBusiness() {
  return (
    <>
      <Header />
      <View style={styles.header}>
          <Text style={styles.headerText}>Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada
          </Text>
        </View>
      <ScrollView style={styles.container}>

        <View style={styles.content}>
          <Text style={styles.paragraph}>
          Canada has seen a growing commitment to supporting female entrepreneurs, with a variety of grants and funding programs designed to empower women in business. Whether you are launching a startup, expanding your company, or seeking professional development, these programs provide valuable financial resources to help female-led businesses succeed. Knowing where to look and how to apply for these grants can open doors to new opportunities, allowing you to grow your business and make a greater impact. Here's a guide to some of the top grants and funding programs available for women entrepreneurs in Canada.
          </Text>

          {/* Section 1 */}
          <Text style={styles.sectionTitle}>Top Grants and Funding Programs for Female Entrepreneurs in Canada</Text>
          <Text style={styles.paragraph}>
          One of the most notable initiatives for female entrepreneurs is the Women Entrepreneurship Strategy (WES). WES is a federal government program aimed at increasing access to financing, talent, networks, and expertise for women in business. As part of this strategy, the Women Entrepreneurship Fund (WEF) provides up to $100,000 in non-repayable funding to women-owned and -led businesses that are ready to scale. The fund supports a range of activities, from market expansion to product development and hiring.
Another valuable program is Futurpreneur Canada's Side Hustle Program, which offers support to young female entrepreneurs looking to grow their side businesses into full-time ventures. The program provides financing of up to $15,000, along with access to mentorship and resources tailored to help women succeed in their industries.
For Indigenous women entrepreneurs, the Indigenous Women's Entrepreneurship Fund offers financial support and resources to help start or expand a business. This fund aims to empower Indigenous women by providing capital, mentorship, and training opportunities to enhance their entrepreneurial skills and business acumen.
In addition to these federal programs, there are various regional grants and funds available to women entrepreneurs across Canada. For example, SheEO is a global community of women supporting women-led ventures through funding and networking opportunities. SheEO provides zero-interest loans to women entrepreneurs, creating an ecosystem of support that goes beyond just financial assistance. These loans are repaid over five years, and the funds are then reinvested in other female-led businesses.
The BDC (Business Development Bank of Canada) also offers programs specifically for women entrepreneurs. The BDC Women in Technology Venture Fund focuses on providing venture capital to tech-based, women-led startups, making it one of the largest funds of its kind globally. The BDC is also committed to supporting women in all sectors through advisory services, business loans, and growth capital.

          </Text>


          {/* Section 2 */}
          <Text style={styles.sectionTitle}>Navigating the Application Process</Text>
          <Text style={styles.paragraph}>
          When applying for grants and funding programs, it's important to understand the requirements and eligibility criteria. Each program has specific goals and guidelines, so it's essential to align your business with these objectives. Typically, you will need to demonstrate that your business is at least 50% women-owned and led. You may also need to provide detailed business plans, financial statements, and projections, as well as explain how the funding will support your business growth or project goals.
Be sure to research each program thoroughly and prepare a compelling application that clearly outlines your business's mission, growth potential, and how you plan to use the funds. This process can be competitive, so take the time to craft a strong proposal and gather all necessary documentation.



          </Text>

          {/* Section 3 */}
          <Text style={styles.sectionTitle}>Tips for Success</Text>
          <Text style={styles.paragraph}>
          When seeking funding as a female entrepreneur, start by exploring all available options, from federal grants to regional programs. Networking with other female business owners and industry experts can provide valuable insights and connect you to potential funding sources. Consider joining women-focused business associations and online communities to learn more about grant opportunities and best practices for applying.
It's also important to stay organized and apply early. Many grants have specific application windows and limited funding, so being proactive can improve your chances of securing financial support. Make sure to tailor each application to the specific program, clearly demonstrating how your business aligns with the fund's objectives and how the funding will be used effectively.


          </Text>


          {/* Section 4 */}
          <Text style={styles.sectionTitle}>Empowering Women in Business</Text>
          <Text style={styles.paragraph}>
          Securing a grant or funding as a female entrepreneur can be transformative, providing the capital needed to launch, grow, or pivot your business. With a range of programs designed specifically for women in business across Canada, there are numerous opportunities to access financial support and gain access to mentorship, networking, and resources. From the Women Entrepreneurship Strategy to regional funds and private programs, female entrepreneurs can find the backing they need to succeed.
Take advantage of the available resources and support, and take the next step in building or expanding your business. With the right funding and determination, female entrepreneurs can make a powerful impact on the Canadian business landscape and beyond.
To access information on grants and funding programs specifically for female entrepreneurs in Canada, you can visit https://findgrant.ca/WomanOwnedBusinessGrants, which provides resources to explore grants tailored to women-led businesses. This platform includes information on programs like the Women Entrepreneurship Fund (WEF) and other opportunities for women in the technology, arts, and business sectors.


          </Text>




    
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>&copy; 2024 FindGrant.ca - All Rights Reserved</Text>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f4f4f4',
  },
  header: {
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    color: '#011F6F',
    marginTop: 30,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 15,
    textAlign: 'justify',
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#011F6F',
  },
  cta: {
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop: 30,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
});
