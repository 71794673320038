import { Pressable, StyleSheet, Text } from "react-native";

const ContactButton1 = ({ onPress = () => {} }) => {
  return (
    <Pressable style={[styles.buttonSearch, { zIndex: 100 }]} onPress={onPress}>
      <Text style={styles.buttonTextSearch}>Contact Us</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonSearch: {
    marginTop: 35,
    marginBottom: 40,
    paddingVertical: 15,

    backgroundColor: "#FFF",
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    borderColor: "#011F6F",
    borderWidth: 2,
  },
  buttonTextSearch: {
    color: "#222",
    fontFamily: "OpenSans-Bold",
    fontSize: 16,
  },
});

export default ContactButton1;