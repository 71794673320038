import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import Header from '../../components/Header';

import Footer from "../../components/Footer"

const screenWidth = Dimensions.get('window').width

export default function FiveMistakes() {
  return (
    <>
    <Header/>
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>5 Mistakes to Avoid When Applying for Canadian Grants</Text>
      </View>

      <View style={styles.content}>
        <Text style={styles.paragraph}>
          Applying for grants in Canada can be a valuable way to secure funding for your business, education, or community project. However, the process can be competitive and complex, and even small errors can result in missed opportunities. To improve your chances of success, it's essential to understand the common pitfalls and how to avoid them. Here are five mistakes to avoid when applying for Canadian grants.
        </Text>
        <Image style={styles.image} source={require("../../../assets/blogs/fiveMistakes/fiveMistakes.jpg")} />

        {/* Mistake 1 */}
        <Text style={styles.sectionTitle}>1. Ignoring Eligibility Requirements</Text>
        {/* <Image style={styles.image} source={require("../../../assets/blogs/fiveMistakes.jpg")} /> */}
        <Text style={styles.paragraph}>
          One of the most critical steps in applying for a grant is ensuring you meet all eligibility requirements. Every grant has its specific criteria, whether it’s based on location, industry type, size of business, or purpose of funding. Ignoring or misunderstanding these requirements can lead to wasted time and effort on an application that may not even be considered.
        </Text>
        <Text style={styles.tip}>
          Tip: Before you apply, read the eligibility guidelines carefully to ensure that you meet all necessary qualifications. It's also important to confirm that the grant aligns with your project's goals and needs.
        </Text>

        {/* Mistake 2 */}
        <Text style={styles.sectionTitle}>2. Submitting an Incomplete or Inaccurate Application</Text>
        {/* <Image style={styles.image} source={{ uri: 'https://example.com/incomplete-application.jpg' }} /> */}
        <Text style={styles.paragraph}>
          Grant applications often require detailed information, including financial documents, project plans, budgets, and other supporting materials. One of the most common mistakes is submitting an incomplete or inaccurate application. Missing documents, incorrect information, or sloppy details can hurt your chances of being considered.
        </Text>
        <Image style={styles.image} source={require("../../../assets/blogs/fiveMistakes/incomplete_applications.png")} />
        <Text style={styles.tip}>
          Tip: Take the time to review all the required documents and double-check the information you provide. If possible, have someone else look over your application before submission to catch any errors or inconsistencies.
        </Text>

        {/* Mistake 3 */}
        <Text style={styles.sectionTitle}>3. Failing to Clearly Define the Purpose and Impact of the Grant</Text>
        {/* <Image style={styles.image} source={{ uri: 'https://example.com/project-impact.jpg' }} /> */}
        <Text style={styles.paragraph}>
          A successful grant application clearly outlines how the funding will be used and the impact it will have on your business, project, or community. Many applicants make the mistake of not being specific enough in their proposals. Grant providers want to see how their funding will contribute to a larger goal, whether that’s boosting business growth, improving community service, or developing a research project.
        </Text>
        <Text style={styles.tip}>
          Tip: Make sure to provide a strong and detailed project plan, complete with objectives, timelines, and measurable outcomes. Clearly explain how the grant will be a catalyst for positive change and why your proposal stands out.
        </Text>

        {/* Mistake 4 */}
        <Text style={styles.sectionTitle}>4. Not Researching the Grant Provider</Text>
        {/* <Image style={styles.image} source={{ uri: 'https://example.com/researching-grant-provider.jpg' }} /> */}
        <Text style={styles.paragraph}>
          Another mistake is failing to research the grant provider and understand their priorities. Every grant has a funder with specific interests, goals, and values, which will influence how they evaluate applications. If you don’t tailor your application to align with the grant provider's mission and criteria, your chances of success diminish.
        </Text>
        <Text style={styles.tip}>
          Tip: Take the time to understand who the funder is, what their goals are, and what types of projects they have supported in the past. This will help you craft an application that speaks to their objectives and demonstrates that your project is a good fit for their funding.
        </Text>

        {/* Mistake 5 */}
        <Text style={styles.sectionTitle}>5. Missing Deadlines and Poor Time Management</Text>
        {/* <Image style={styles.image} source={{ uri: 'https://example.com/time-management.jpg' }} /> */}
        <Text style={styles.paragraph}>
          Applying for grants can be a time-consuming process, and deadlines are strict. One of the most preventable mistakes is missing the application deadline or not allocating enough time to complete the application properly. Many grants have specific windows for submission, and late applications are often not accepted.
        </Text>
        <Text style={styles.tip}>
          Tip: To avoid this, start the application process early, allowing plenty of time to gather required documents, write proposals, and revise as needed. Create a schedule with key milestones to ensure that you stay on track and meet all deadlines comfortably.
        </Text>

        {/* Conclusion */}
        <Text style={styles.sectionTitle}>The Bottom Line: Plan and Prepare</Text>
        <Image style={styles.image} source={require("../../../assets/blogs/fiveMistakes/incomplete applications.avif")} />
        <Text style={styles.paragraph}>
          Applying for Canadian grants requires careful planning, attention to detail, and an understanding of both the requirements and the grant provider's priorities. By avoiding these common mistakes—ignoring eligibility, submitting incomplete applications, lacking a clear purpose, failing to research the funder, and poor time management—you can significantly increase your chances of being awarded funding.
        </Text>
        <Text style={styles.paragraph}>
          Remember that a well-prepared, clear, and compelling application not only meets all the requirements but also tells a strong story about how the grant will make a meaningful impact. So start early, plan thoroughly, and make sure to put your best foot forward when seeking financial support for your business, educational goals, or community projects in Canada.
        </Text>

        {/* Call to Action */}
        <View style={styles.cta}>
          <Text style={styles.ctaText}>
            For more information on Canadian grants and to avoid common application mistakes, check out{' '}
            <TouchableOpacity onPress={() => Linking.openURL('https://www.findgrant.ca')}>
              <Text style={styles.ctaLink}>FindGrant.ca</Text>
            </TouchableOpacity>. This resource offers guidance on eligibility, crafting strong applications, and managing deadlines effectively.
          </Text>
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>&copy; 2024 FindGrant.ca - All Rights Reserved</Text>
      </View>
      <Footer/>
    </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f4f4f4',
  },
  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
    // paddingHorizontal: '10%',
  },
  sectionTitle: {
    fontSize: 20,
    color: '#2d3e50',
    color: '#011F6F',
    marginTop: 30,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    color: '#555',
    color: '#011F6F',
    // lineHeight: 2.8,
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 350: 500,
    borderRadius: 10,
    marginVertical: 10,
    alignSelf: 'center',
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop: 30,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
});
