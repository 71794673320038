export const fetchFundingPrograms = async (id) => {
  try {
    const response = await fetch(
      `https://api.findgrant.ca/webpage/funding-programs/${id}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch program with ID: ${id}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
