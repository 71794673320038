import React from 'react';
import { ScrollView, Text, View, StyleSheet, Image } from 'react-native';
import Header from '../../components/Header';

export default function HowToFind () {
  return (
    <ScrollView style={styles.container}>
        <Header/>
        {/* Hero Image */}
        {/* <Image
            source={{ uri: 'https://example.com/hero-image.jpg' }} // Replace with actual image URL
            style={styles.heroImage}
        /> */}

        <View style={styles.content}>
            {/* Title */}
            <View style={styles.headerSection} >
                <Text style={styles.title}>How to Find Grants for Non-Profit Organizations in Canada</Text>

                {/* Introduction */}
                <Text style={styles.paragraph}>
                Finding grants for non-profit organizations in Canada is crucial for obtaining financial support for important
                community, social, or environmental initiatives. By understanding where to look and how to navigate the application
                process, you can enhance your chances of securing valuable funding.
                </Text> 
            </View>

            {/* Section 1: Government Grants */}
            <Text style={styles.sectionTitle}>Government Grants for Non-Profits</Text>
            <Text style={styles.paragraph}>
            Government funding is one of the most reliable sources of financial support for non-profits in Canada. Start by
            exploring the Government of Canada’s funding programs database, which includes grants for a wide variety of sectors
            such as health, community development, arts, and the environment. The federal government offers programs like
            Canada’s Social Development Partnerships Program and New Horizons for Seniors Program, which provide funding for
            social development and community-building initiatives. Each province also offers specific regional grants, so it’s
            beneficial to research your local government’s funding opportunities. Don’t forget about municipal grants, which often
            support local, smaller-scale projects aimed at community improvement or engagement.
            </Text>

            {/* Government Grants Image */}
            {/* <Image
            source={{ uri: 'https://example.com/government-grants-image.jpg' }} // Replace with actual image URL
            style={styles.image}
            /> */}

            {/* Section 2: Private Foundations and Charitable Organizations */}
            <Text style={styles.sectionTitle}>Private Foundations and Charitable Organizations</Text>
            <Text style={styles.paragraph}>
            Beyond government grants, non-profits can tap into funding from private foundations. Major Canadian foundations like the
            McConnell Foundation, Trillium Foundation, and J.W. McConnell Family Foundation provide generous grants to non-profits
            working in areas such as social justice, climate change, and community resilience. To streamline the search process, you
            can use grant databases like Grant Connect and Imagine Canada, which allow you to search for grant opportunities by
            sector, region, and type of project. These platforms connect you to thousands of funders across Canada, helping you
            identify those that best match your mission.
            </Text>

            {/* Private Foundations Image */}
            {/* <Image
            source={{ uri: 'https://example.com/private-foundations-image.jpg' }} // Replace with actual image URL
            style={styles.image}
            /> */}

            {/* Section 3: Networking and Partnerships */}
            <Text style={styles.sectionTitle}>Networking and Partnerships</Text>
            <Text style={styles.paragraph}>
            Networking with other non-profits and community organizations is an often overlooked but effective way to find new grant
            opportunities. By collaborating with other organizations in your sector, you can learn about upcoming grants, share best
            practices for applying, and even form partnerships that strengthen your applications. Joining non-profit associations or
            attending events such as conferences and workshops can help you meet potential partners or funders. Many non-profits share
            valuable insights on how they secured funding, which can provide you with new strategies and tips for successful grant
            writing.
            </Text>

            {/* Networking Image */}
            {/* <Image
            source={{ uri: 'https://example.com/networking-image.jpg' }} // Replace with actual image URL
            style={styles.image}
            /> */}

            {/* Section 4: Writing a Winning Grant Proposal */}
            <Text style={styles.sectionTitle}>Writing a Winning Grant Proposal</Text>
            <Text style={styles.paragraph}>
            Once you’ve found potential grants, writing a strong proposal is critical. Begin by ensuring your application clearly
            outlines the mission of your non-profit, the project that requires funding, and the measurable impact it will have. Funders
            need to understand how their money will be used and the tangible benefits it will create for the community. A detailed
            project plan, complete with a budget and timeline, is essential for demonstrating that you are organized and capable of
            successfully executing your proposed initiative.
            </Text>

            {/* Grant Proposal Image */}
            {/* <Image
            source={{ uri: 'https://example.com/grant-proposal-image.jpg' }} // Replace with actual image URL
            style={styles.image}
            /> */}

            {/* Conclusion */}
            <Text style={styles.paragraph}>
            Finding grants for non-profit organizations in Canada requires strategic research, planning, and an understanding of the
            specific goals and values of each grant provider. By exploring government programs, private foundations, and fostering
            partnerships within the non-profit sector, you can significantly enhance your ability to secure funding. Writing strong,
            tailored applications that highlight your non-profit’s mission and impact is key to standing out in a competitive
            environment. With careful planning and persistence, you can find the financial support needed to bring your non-profit’s
            projects to life and make a lasting difference in your community.
            </Text>

            {/* FindGrant Call to Action */}
            <Text style={styles.callToAction}>
            For non-profit organizations in Canada seeking grants, FindGrant.ca serves as a comprehensive resource. It offers access to
            funding opportunities across sectors, including specific grants for social development, community-building, and
            environmental projects.
            </Text>

        </View>
    </ScrollView>
  );
};

// Styles for the Blog Post
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 15,
    backgroundColor: '#fff',
    backgroundColor: '#f9f9f9',
  },
  content: {
    // marginTop: 20,
  },
  title: {
    marginBottom: 16,
    marginTop: 10,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 22,
    // marginBottom: 15,

    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    // marginTop: 20,
    marginHorizontal: 10,

    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    marginHorizontal: 20,
    
  },
  image: {
    width: '100%',
    height: 200,
    marginVertical: 15,
    resizeMode: 'cover',
  },
  heroImage: {
    width: '100%',
    height: 300,
    marginBottom: 20,
    resizeMode: 'cover',
  },
  callToAction: {
    fontSize: 16,
    fontWeight: '600',
    color: '#0066CC',
    marginTop: 20,
  },
});
