import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Image, Modal } from 'react-native-web'

const IssueReported = ({ visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="fade"
    >
      <View style={styles.overlay}>
        <View style={styles.IssueReportedContainer}>
          <View style={styles.titleDescriptionContainer}>
            <Text style={styles.title}>Your Issue is Reported</Text>
            <Text style={styles.description}>Thank you for helping us improve our website!</Text>
            <Text style={styles.description}>This Structure allows users to specify what type of issue they're reporting while still keeping it simple</Text>
          </View>

          <Pressable onPress={onClose}>
            <Image style={styles.closeImage} source={require("../../../assets/close.png")}></Image>
          </Pressable>
        </View>
      </View>
    </Modal>
  )
}

export default IssueReported

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  IssueReportedContainer: {
    width: 780,
    height: 257,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: 'white',
    borderRadius: 60,
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-around"


  },
  closeImage: {
    height: 16,
    width: 16,
    position: "absolute",
    top: 20,
  },

  title: {
    fontSize: 24,
    color: "#011F6F",
    fontWeight: '600',
    fontFamily: "OpenSans-Semibold",
  },
  description: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "OpenSans-Regular",
    marginBottom: 15
  },
  titleDescriptionContainer: {
    width: "60%",
    justifyContent: 'space-evenly'
  },

});