import { Pressable, StyleSheet, Text, View, Dimensions } from 'react-native'
import React, { useState } from 'react'
import { CheckBox, Image } from 'react-native-web'

const screenWidth = Dimensions.get('window').width

const ExactMatch = ({ match, setMatch, activeIcon, setActiveIcon }) => {




  const handlePress = (iconType) => {
    setActiveIcon(iconType);
    setMatch(false);
  };

  return (
  <View style={styles.iconsContainer}>
    {screenWidth < 650 ? (
        <View style={styles.iconsContainer}>
        <Pressable
          style={[
            styles.eachIcon,
            activeIcon === 'all' && match === false && styles.activeIcon
          ]}
          onPress={() => handlePress('all')}
        >
          <Image style={styles.icons} source={require("../../assets/searchPage/search.svg")} />
          <Text style={styles.text}>All</Text>
        </Pressable>
        
        <Pressable
          style={[
            styles.eachIcon,
            styles.checkboxContainer,
            match && styles.activeIcon
          ]}
          onPress={() => setMatch(!match)}
        >
          <CheckBox
            value={match}
            onValueChange={setMatch}
            style={styles.checkbox}
          />
          <Text style={styles.text}>Exact match</Text>
        </Pressable>

        {/* <View style={styles.dividerContainer}>
          <View style={styles.divider}></View>
        </View> */}
      </View>
    ) : (
      <View style={styles.iconsContainer}>
        <Pressable
          style={[
            styles.eachIcon,
            activeIcon === 'all' && match === false && styles.activeIcon
          ]}
          onPress={() => handlePress('all')}
        >
          <Image style={styles.icons} source={require("../../assets/searchPage/search.svg")} />
          <Text style={styles.text}>All</Text>
        </Pressable>
          {/* <Pressable onPress={() => setMatch(!match)} style={styles.eachIcon}>
            <Image style={match === true ? styles.ExactMatchTrue : styles.ExactMatchFalse} source={require("../../assets/searchPage/checked.png")} />
          </Pressable> */}


        <Pressable
          style={[
            styles.eachIcon,
            styles.checkboxContainer,
            match && styles.activeIcon
          ]}
          onPress={() => setMatch(!match)}
        >
          <CheckBox
            value={match}
            onValueChange={setMatch}
            style={styles.checkbox}
          />
          <Text style={styles.text}>Exact match</Text>
        </Pressable>

        {/* <View style={styles.dividerContainer}>
          <View style={styles.divider}></View>
        </View> */}
      </View>
    )}
  </View >
  )
}

export default ExactMatch

const styles = StyleSheet.create({
  eachIcon: {
    // borderWidth: 2,
    // borderColor: "black",
    // width: 80,
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // cursor: "pointer",
    // height: 100,
    // marginLeft: 10,

    // width: 100,
    // textAlign: 'center',
    // cursor: "pointer",
    width: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  text: {
    textAlign: "center",
    color: "#6C757D",
    fontFamily: "OpenSans-SemiBold"
  },
  icons: {
    height: 28,
    width: 28,
    alignSelf: "center",
    marginBottom: 12,
  },
  iconsContainer: {
    flexDirection: 'row',
    width: 330,
    height: 90,
    // borderWidth: 2,
    // borderColor: "red",
    // display: "flex",
    justifyContent: screenWidth < 650 ? "center" : 'center',
    // alignItems: "center",
    // backgroundColor: 'red',
    // marginLeft: screenWidth < 650 ? 20: null,
  },
  // ExactMatchTrue: {
  //   height: 26,
  //   width: 26,
  //   alignSelf: "center",
  //   tintColor: "green",
  //   marginBottom: 12
  // },
  // ExactMatchFalse: {
  //   height: 26,
  //   width: 26,
  //   alignSelf: "center",
  //   tintColor: "grey",
  //   marginBottom: 12
  // },
  divider: {
    width: 2,
    height: 45,
    backgroundColor: "#ebebeb",
    textAlign: "center"
  },
  dividerContainer: {
    width: 40,
    // alignItems: "center",
    // borderColor: "black",
    // borderWidth: 2,
    height: 90,
    justifyContent: "center",
    paddingLeft: 30
  },
  checkbox: {
    height: 26,
    width: 26,
    alignSelf: "center",
    marginBottom: 12,

  },
  activeIcon: {
    borderBottomWidth: 2,
    borderBottomColor: "#011f6f",
    borderRadius: 1
  }
})