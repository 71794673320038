import React from 'react';
import { View, Text, Pressable, Linking, StyleSheet } from 'react-native';
import { versionNum } from "../../../constants";

const FooterVersion = ({ footerStyle, footerTextStyle }) => {
  return (
    <View style={[styles.footer, footerStyle]}>
      <Pressable
    //    onPress={() => Linking.openURL('https://docs.google.com/forms/d/1LKRURN2jhC9kHpNXGpK_PXUvsFq82Qecv0GG0hMoCQo')}
       >
        <Text style={[styles.footerText, footerTextStyle]}>Send Us Feedback |  © 2024 FindGrant.ca</Text>
      </Pressable>
      <Text style={[styles.footerText, footerTextStyle]}>
        <Pressable onPress={() => { navigation.navigate("Privacy") }}>
          <Text>Privacy Policy</Text>
        </Pressable> | 
        <Pressable onPress={() => { navigation.navigate("TOS") }}>
          <Text>TOS</Text>
        </Pressable> | Version 1.0.{versionNum}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    backgroundColor: "#011F6F",
    marginTop: 0,
    alignItems: 'flex-start',
    width: "100%",
    alignItems: 'center',
    paddingTop: 10,
  },
  footerText: {
    marginBottom: 20,
    color: 'white',
    fontSize: 12,
  },
});

export default FooterVersion;