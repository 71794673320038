import { StyleSheet, View, Text, Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;
 
const HeroSimpleTitle = ( {title, subtitle} ) => {  
  return (
    <>
        <View style={styles.container_header}>
          <Text style={styles.text_title}>
            {title}
          </Text>
          <Text style={styles.text_sub}>
            {subtitle}
          </Text>
      </View> 
    </>
  );
};

const styles = StyleSheet.create({
  container_header: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: 60,
    paddingBottom: 60,
    backgroundColor: "#011F6F", // applies white default background
    marginTop: 10,
    marginBottom: screenWidth < 580  ? 30 : screenWidth < 881  ? 30 : screenWidth < 1080  ? 40 : screenWidth < 1343  ? 40 : 40,  
    paddingHorizontal: screenWidth < 580  ? 40 : screenWidth < 881  ? 70 : screenWidth < 1080  ? 50 : screenWidth < 1343  ? 50 : 50,  
  },
  text_title: {
    color: "#FFF",
    fontSize: 60,
    fontFamily: "Montserrat-Bold",
    fontWeight: 700,
    lineHeight: 78.02,
    marginBottom: 50,
    textAlign: "center",
  },
  text_sub: {
    color: "#FFF",
    fontSize: 18,
    fontFamily: "OpenSans-Regular",
    fontWeight: 400,
    lineHeight: 21.79,
    textAlign: "center",
  },
});

export default HeroSimpleTitle;
