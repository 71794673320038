import { StyleSheet, View, TextInput, Pressable, Image, Dimensions,Text } from 'react-native';
import React, { useState } from 'react';
import axios from 'axios';

const screenWidth = Dimensions.get("window").width;

const EmailInput = () => {
  const [isEmailSubmit,setEmailSubmit]= useState(false);
  const [email, setEmail] = useState('');
  const [ErrorMsg,setErrorMsg]= useState('');
  const [isInputVisible, setInputVisible] = useState(true);


  const handleEmaiLChange=(text)=>{
    setEmail(text);
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(email)){
      setErrorMsg("Please enter a valid email.");
    }else{
      setErrorMsg("");
    }
  } 

  const handleSubmitEmail = (email) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    if(emailRegex.test(email)){
      setEmailSubmit(true)
      storeEmail();
      setTimeout(() => {
        setEmailSubmit(false); 
        setInputVisible(false); 
      }, 1500);

    }
    else {
      setErrorMsg("Please enter a valid email.");
    }
  }

  const storeEmail = () => {
    const link = "https://api.trustnet.site/join_waitlist"; 
    const props = {
      email: email, 
    };

    axios
      .post(link, props, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          console.log("Email stored successfully.");
          console.log(`Email id is: ${email}`);
        } else {
          console.error("Failed to store email.");
        }
      })
      .catch((error) => {
        console.error("Error occurred while storing email:", error);
      });
  };

  return (
    isInputVisible ? (
    !isEmailSubmit?(
      <View>
    <View style={styles.EmailInputContainer}>
      <TextInput
        style={styles.input}
        onChangeText={handleEmaiLChange}
        placeholder="Enter your Email"
        placeholderTextColor="#B3B3B3"
      />
      <Pressable style={styles.buttonStyle}
        onPress={() => handleSubmitEmail(email)}
      >
        <Image
          style={styles.img}
          source={require("../../../assets/right-arrow.png")}
        />
      </Pressable>
    </View>
      <Text style={styles.ErrMsg}>
        {ErrorMsg}
      </Text>
    </View>
    ):(
      <View>
        <Text style={styles.Greeting}>Thanks</Text>
        <Image
          style={styles.CheckMark}
          source={require('../../../assets/searchPage/check-mark.png')}    
        />
      </View>
    )
  ):null);
};

export default EmailInput;

const styles = StyleSheet.create({
  input: {
    width: screenWidth < 881  ? 310 : screenWidth < 1261  ? 310 : screenWidth < 1001  ? 310 : 310,
    height: 62,
    borderRadius: 60,
    backgroundColor: "#FFFFFF",
    paddingLeft: screenWidth < 881  ? 20 : screenWidth < 1261  ? 25 : screenWidth < 1080  ? 25 : 25,
    fontSize: 18,
    fontFamily: "OpenSans-Regular",
  },
  EmailInputContainer: {
    paddingTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonStyle: {
    position: "absolute",
    right: 50,
    backgroundColor: "#F5BF4C",
    height: 62,
    width: 62,
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: 24,
    height: 24,
    tintColor: "#FFFFFF",
  },
  Greeting: {
    color: "white",
    fontSize: 22,
    fontWeight: 700,
    paddingTop: 16,
    fontFamily: "OpenSans-Regular",
    
  },
  CheckMark:{
    height: 50,
    width: 50,
    position: 'absolute',
    left: 100,
    top: 10,
    tintColor: '#6fdc6f',
  },
  ErrMsg:{
    color: "#f5bf4c",
    fontSize: 19,
    fontWeight: 700,
    marginTop: 16,
    marginLeft: 10,    
    fontFamily: "OpenSans-Regular",
  }
});
