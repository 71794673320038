import React, { useState, useRef, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Dimensions
} from "react-native";

const screenWidth = Dimensions.get("window").width;

const SearchBarForSearchProgram = ({
  title,
  placeholder,
  items,
  style,
  children,
  onChange,
  defaultValue = "",
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef();

  const handleSearch = (text) => {
    setInputValue(text);
    const filteredData = items.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredItems(filteredData);
    setIsDropdownVisible(true); // Show dropdown when typing
  };

  const handleItemSelect = (option) => {
    setInputValue(option.label);
    setIsDropdownVisible(false); // Hide dropdown after selection
    //Propagate selected value to parent via onChange
    if (onChange) {
      onChange(option.label);
    }
  };

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Hide dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setInputValue(defaultValue); // Asegurarse de actualizar si defaultValue cambia
  }, [defaultValue]);

  return (
    <View style={styles.outerContainer}>
      <View style={[styles.container, style]} ref={dropdownRef}>
        {/* Search and Select Input */}
        <View style={styles.placeholder}>
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          <TextInput
            value={inputValue}
            onChangeText={handleSearch}
            placeholder={placeholder}
            style={[styles.input, inputValue !== "" && styles.selectedText]}
            onFocus={() => setIsDropdownVisible(true)} // Show dropdown on focus
          />
        </View>

        {/* Dropdown list */}
        {isDropdownVisible && (
          <View style={[styles.dropdown, { zIndex: 1000 }]}>
            <FlatList
              data={inputValue === "" ? items : filteredItems} //if input is empty, displays all options.
              keyExtractor={(option) => option.id.toString()}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={styles.dropdownItem}
                  onPress={() => handleItemSelect(item)}
                >
                  <Text>{item.label}</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        )}

        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: 220,
    height: 70,
  },
  placeholder: {
    paddingLeft: 25,
    paddingTop: 12,
  },
  input: {
    paddingTop: 5,
    fontSize: 13,
    color: "grey",
    outlineStyle: "none", //remove text input border
    fontFamily: "OpenSans-Semibold",
  },
  dropdown: {
    position: "absolute",
    top: screenWidth < 500 ? -122: -100,
    // left: 20,
    width: screenWidth < 500 ? "70%" : "80%",
    maxHeight: screenWidth < 500 ? 200 : 250,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    fontFamily: "OpenSans-Semibold",

    left:
      screenWidth < 500
      ? 170
      : screenWidth < 950
        ? -175
        : screenWidth < 1261
        ? -175
        : screenWidth < 1080
        ? 40
        : 40,
  },
  dropdownItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  selectedText: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "OpenSans-Semibold",
  },
  outerContainer: {
  },
});

export default SearchBarForSearchProgram;
