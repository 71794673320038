import React, { forwardRef } from "react";


import { ScrollView, StyleSheet, View, Dimensions } from "react-native";

const Layout = ({ children }) => {
  return (
    <ScrollView
      style={styles.verticalScrollView}
      showsVerticalScrollIndicator={true}
    >
      {/* <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        contentContainerStyle={styles.horizontalScrollView}
      >
        <View style={styles.container}>{children}</View>
      </ScrollView> */}
      <View
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        contentContainerStyle={styles.horizontalScrollView}
      >
        <View style={styles.container}>{children}</View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  verticalScrollView: {
    flex: 1,
  },
  horizontalScrollView: {
    flexGrow: 1,
    minWidth: "100%",
    minHeight: "100%",
  },
  container: {
    flex: 1,
    minWidth: Dimensions.get("window").width,
    minHeight: Dimensions.get("window").height,
    alignItems: "center",
    backgroundColor: "#FFF", // white backgroud
    position: "relative",
  },
});

export default Layout;
