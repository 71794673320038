import { View, Text, StyleSheet, Image, Pressable, Modal } from "react-native";
import React, { useState } from "react";
import Blog from "./Blogs";
import DropdownMenu from "./DropdownMenu";
import { useWindowDimensions, Dimensions } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

const screenWidth = Dimensions.get("window").width;

const Navbar = ({}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isBlogVisible, setBlogVisible] = useState(false);
  const { width } = useWindowDimensions(); // Get the screen width
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    console.log("Toggling menu");
  };

  const navigation = useNavigation();
  const route = useRoute();

  const handleSearchNow = () => {
    if (route.name === "Home") {
      navigation.setParams({ scrollToSearchBar: true });
    } else {
      navigation.navigate("Home", { scrollToSearchBar: true });
    }
  };

  return (
    <View>
      <View style={styles.navBar}>
        {width < 930 ? (
          <Pressable style={styles.hamburgerMenu} onPress={toggleMenu}>
            <View style={styles.hamburgerLine}></View>
            <View style={styles.hamburgerLine}></View>
            <View style={styles.hamburgerLine}></View>
          </Pressable>
        ) : (
          <View style={styles.navBar}>
            <Text style={styles.NavText}>
              <a href="/Home" style={styles.Link}>
                Home
              </a>
            </Text>
            <Text style={styles.NavText}>
              <a href="/AboutUs" style={styles.Link}>
                About us
              </a>
            </Text>
            <Text style={styles.NavText}>
              <a href="/contactUs" style={styles.Link}>
                Contact us
              </a>
            </Text>

            <View style={styles.navItemWithIcon}>
            <Pressable
              style={styles.navItemWithIcon}
              onPress={() => {setBlogVisible(!isBlogVisible); 
                if (isDropdownVisible) setDropdownVisible(false);
              }}
            >
              <Text style={[styles.NavText, { pointerEvents: "none" }]}>
                Blogs
              </Text>
              <Image
                style={styles.img}
                source={require("../../../assets/drop-down.png")}
              />
            </Pressable>

            {isBlogVisible && (
              <View style={styles.dropdownContainer}>
                <Blog onClose={() => setBlogVisible(false)} />
              </View>
            )}
          </View>

            <View style={styles.navItemWithIcon}>
              <Pressable
                style={styles.navItemWithIcon}
                onPress={() => {setDropdownVisible(!isDropdownVisible);
                  if (isBlogVisible) setBlogVisible(false);
                }}
              >
                <Text style={[styles.NavText, { pointerEvents: "none" }]}>
                  Grants for Diverse Communities
                </Text>
                <Image
                  style={styles.img}
                  source={require("../../../assets/drop-down.png")}
                />
              </Pressable>

              {isDropdownVisible && (
                <View style={styles.dropdownContainer}>
                  <DropdownMenu onClose={() => setDropdownVisible(false)} />
                </View>
              )}
            </View>

            <Pressable style={styles.buttonSearch}
              onPress={() => navigation.navigate("ProgramSearch" )}>
              <Text style={styles.buttonTextSearch}>Search Now</Text>
            </Pressable>
          </View>
        )}
      </View>

      {/* Render the menu if open */}
      {isMenuOpen && (
        <View style={styles.dropdownMenu}>
          <Text style={styles.NavText}>
            <a href="/Home" style={styles.Link}>
              Home
            </a>
          </Text>
          <Text style={styles.NavText}>
            <a href="/AboutUs" style={styles.Link}>
              About us
            </a>
          </Text>
          <Text style={styles.NavText}>
            <a href="/contactUs" style={styles.Link}>
              Contact us
            </a>
          </Text>

          <View style={styles.navItemWithIcon}>
            <Pressable
              style={styles.navItemWithIcon}
              onPress={() => {setBlogVisible(!isBlogVisible); 
                if (isDropdownVisible) setDropdownVisible(false);
              }}
            >
              <Text style={[styles.NavText, { pointerEvents: "none" }]}>
                Blogs
              </Text>
              <Image
                style={styles.img}
                source={require("../../../assets/drop-down.png")}
              />
            </Pressable>

            {isBlogVisible && (
              <View style={styles.dropdownContainer}>
                <Blog onClose={() => setBlogVisible(false)} />
              </View>
            )}
          </View>

          <View style={styles.navItemWithIcon}>
            <Pressable
              style={styles.navItemWithIcon}
              onPress={() => {setDropdownVisible(!isDropdownVisible);
                if (isBlogVisible) setBlogVisible(false);
              }}
            >
              <Text style={[styles.NavText, { pointerEvents: "none" }]}>
                Grants for Diverse Communities
              </Text>
              <Image
                style={styles.img}
                source={require("../../../assets/drop-down.png")}
              />
            </Pressable>

            {isDropdownVisible && (
              <View style={styles.dropdownContainer}>
                <DropdownMenu onClose={() => setDropdownVisible(false)} />
              </View>
            )}
          </View>

          <View style={styles.buttonContainer}>
            <Pressable style={styles.buttonSearch} 
              onPress={() => navigation.navigate("ProgramSearch" )}>
              <Text style={styles.buttonTextSearch}>            
                  Search Now
              </Text>
            </Pressable>
          </View>
        </View>
      )}
      {/* temporary */}
      {/* <Pressable style={styles.buttonSearch} onPress={onReportIssue}>
        <Text style={styles.buttonTextSearch}>Search Now</Text>
      </Pressable> */}
    </View>
  );
};

export default Navbar;

const styles = StyleSheet.create({
  navBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent:
      screenWidth < 700
        ? "space-between"
        : screenWidth < 821
        ? "space-between"
        : screenWidth < 1057
        ? "space-around"
        : "space-around",
    width:
      screenWidth < 700
        ? "100%"
        : screenWidth < 821
        ? "100%"
        : screenWidth < 1057
        ? "105%"
        : "110%",
    height: 49,
    // backgroundColor: 'blue',
    left:
      screenWidth < 700
        ? null
        : screenWidth < 821
        ? null
        : screenWidth < 1057
        ? "-3.5vw"
        : "-4.5vw",
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 5,
  },
  buttonSearch: {
    paddingTop: 10,
    width:
      screenWidth < 700
        ? "50%"
        : screenWidth < 821
        ? "50%"
        : screenWidth < 1057
        ? null
        : null,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    backgroundColor: "#F5BF4C",
    borderRadius: 30,
  },
  buttonTextSearch: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans-Regular",
  },
  NavText: {
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 28.8,
    paddingVertical: 2,
  },
  navItemWithIcon: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
  },
  img: {
    width: 16,
    height: 16,
    marginLeft: 5,
    display: "flex",
  },
   dropdownMenu: {
    position: "absolute",
    top: 50,
    right: 0,
    // width: 240,
    width: 285,
    padding: 15,
    backgroundColor: "#fff",
    borderRadius: 10,
    borderColor: "#dbdbdb",
    borderWidth: 1,
    zIndex: 10,
  },
  dropdownContainer: {
    position: "absolute",
    top: screenWidth < 700  ? 85 : screenWidth < 821  ? 85 : screenWidth < 1057  ? 31 : 31,
    zIndex: 1000,
  },  
  Link: {
    textDecorationLine: "none",
    color: "black",    
  },
  hamburgerLine: {
    width: 30,
    height: 4,
    backgroundColor: "#333",
    marginVertical: 3,
    borderRadius: 2,
  },
});
