import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity, Linking } from 'react-native';

import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function TopGovernmentGrants() {
  return (
    <>
      <Header />
      <View style={styles.header}>
          <Text style={styles.headerText}> Top Government Grants Available for Canadians in 2024 </Text>
        </View>
      <ScrollView style={styles.container}>

        <View style={styles.content}>
          <Text style={styles.paragraph}>
          In 2024, Canadians have access to a wide array of government grants designed to help individuals, businesses, and organizations achieve their goals without the burden of debt. These grants, provided by federal, provincial, and local governments, span numerous sectors such as business development, education, housing, sustainability, and community projects. With the right knowledge and a well-prepared application, Canadians can access significant funding to drive their success in the coming year. Here's a detailed overview of the top government grants available for Canadians in 2024.
          </Text>

          {/* Section 1 */}
          <Text style={styles.sectionTitle}>Business Grants for Entrepreneurs and Small Businesses</Text>
          <Text style={styles.paragraph}>
          For entrepreneurs and small business owners, 2024 offers several key grants to help start, expand, or innovate their businesses. One of the most prominent programs is the Canada Small Business Financing Program (CSBFP). This program provides financial assistance to small businesses seeking funding for operational needs, such as purchasing new equipment, upgrading facilities, or expanding operations. It allows small business owners to access loans up to $1 million, which can be used to buy or improve business assets. With the government partially backing these loans, businesses can secure financing that may otherwise be difficult to obtain.
Another valuable grant is the Canada Job Grant, which helps businesses cover the costs of training employees to enhance their skills and improve overall productivity. The program allows employers to apply for funding to train both new and existing employees. It provides coverage for up to two-thirds of training costs, depending on the province, with maximum funding of up to $10,000 per employee. This grant is ideal for businesses looking to invest in their workforce while keeping operational costs manageable.
For high-growth businesses, the Business Scale-Up and Productivity Program provides essential funding for companies operating in sectors like technology, clean energy, or manufacturing. This program focuses on businesses that show significant growth potential and are looking to improve their productivity or enter new markets. The funding typically covers up to 50% of project costs and can be used for a range of purposes, including product development, market expansion, and commercialization activities

          </Text>


          {/* Section 2 */}
          <Text style={styles.sectionTitle}>Housing and Sustainability Grants for Homeowners</Text>
          <Text style={styles.paragraph}>
          For Canadians focused on homeownership and sustainability, there are several grant programs available in 2024 that provide financial assistance for purchasing homes and making energy-efficient upgrades. The Canada Greener Homes Grant is a popular program that encourages homeowners to adopt more sustainable living practices by upgrading their homes with energy-efficient improvements. This grant offers up to $5,000 to cover the cost of renovations like installing better insulation, replacing windows and doors, or upgrading heating and cooling systems. These improvements help reduce energy consumption and lower utility bills while contributing to Canada's broader sustainability goals.
First-time homebuyers can benefit from the First-Time Home Buyer Incentive, a program that reduces monthly mortgage payments without increasing down payment requirements. Under this program, the government offers a shared equity mortgage, contributing between 5-10% of the home's purchase price. This reduces the financial burden on first-time buyers, making homeownership more accessible to young Canadians or those entering the housing market for the first time.

          </Text>

          {/* Section 3 */}
          <Text style={styles.sectionTitle}>Agricultural Grants for Farmers and Agri-Businesses</Text>
          <Text style={styles.paragraph}>
          Agriculture remains a critical industry in Canada, and the government continues to support farmers and agri-businesses through a variety of grant programs. The Canadian Agricultural Partnership (CAP) is a key program providing financial assistance to farmers looking to adopt new technologies, improve sustainability, or enhance productivity on their farms. CAP offers funding for a wide range of agricultural projects, including soil and water management, energy efficiency improvements, and market development initiatives.
Another important program is Farm Credit Canada (FCC), which offers low-interest loans and grants to help farmers modernize their operations, expand their businesses, or invest in new technology. FCC provides a variety of funding options to support agricultural innovation, sustainability, and growth, making it easier for farmers to remain competitive in the global market.

          </Text>


          {/* Section 4 */}
          <Text style={styles.sectionTitle}>Health and Disability Grants for Canadians</Text>
          <Text style={styles.paragraph}>
          The Canadian government also provides grants to support Canadians living with disabilities or health challenges. One of the most well-known programs is the Disability Tax Credit (DTC), which offers tax relief to individuals with disabilities or those supporting someone with a disability. This tax credit helps reduce the amount of income tax owed and can provide significant financial relief to individuals with long-term health conditions.
For those looking to save for the future, the Registered Disability Savings Plan (RDSP) is a valuable program that helps individuals with disabilities and their families save for long-term financial security. Contributions to an RDSP are matched by the Canada Disability Savings Grant, with the government contributing up to $3,500 annually and a lifetime limit of $70,000.
For an extensive guide on government grants available in Canada for 2024, FindGrant.ca offers a centralized resource to explore various funding opportunities across sectors such as business, housing, sustainability, and education.
          </Text>



    
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>&copy; 2024 FindGrant.ca - All Rights Reserved</Text>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f4f4f4',
  },
  header: {
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    color: '#011F6F',
    marginTop: 30,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 15,
    textAlign: 'justify',
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#011F6F',
  },
  cta: {
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop: 30,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
});
