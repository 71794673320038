import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'

const LogoFooter = () => {
  return (
    <View style={styles.logoFooterContainer}>
      <a href="/" style={styles.Link}>
        <Image
          style={styles.img}
          source={require("../../../assets/Findgrant White Logo.png")}
        />
      </a>
    </View>
  )
}

export default LogoFooter

const styles = StyleSheet.create({
  img: {
    // backgroundColor: "white",
    width: 192,
    height: 39,
    resizeMode: "contain",
    // tintColor: "#FFFFFF"

  },
  logoFooterContainer: {
    // width: 360,
    display: "flex",
    alignItems: "center",
    // paddingTop: 76
  }
})