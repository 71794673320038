import React from 'react';
import { View, Text, ScrollView, StyleSheet, Linking, Image, Dimensions } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


const screenWidth = Dimensions.get('window').width

export default function EducationGrants() {
  return (
    <>
      <Header />
      <ScrollView style={styles.container}>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Education Grants in Canada: Opportunities for Students and Educators</Text>
        </View>

        <View style={styles.contentContainer}>
          <Image style={styles.image} source={require("../../../assets/blogs/EducationGrants/education_fundings.png")} />
          <Text style={styles.paragraph}>
            Canada offers a wide range of education grants that can help ease the financial burden of pursuing further studies, developing educational programs, or enhancing teaching skills. Whether you are a student looking to fund your studies, a teacher seeking professional development, or an institution aiming to support specific programs, there are numerous opportunities to access financial aid. These grants can make a significant difference, often covering tuition costs, course materials, or development initiatives without the need for repayment. Here's what you need to know about education grants in Canada.
          </Text>

          <Text style={styles.subheading}>The Importance of Education Grants in Canada</Text>
          <Text style={styles.paragraph}>
            Education costs can be a significant obstacle for students, educators, and institutions. From tuition fees and school supplies to program development and training, the financial demands of the educational sector can be high. Grants serve as a critical support mechanism, providing much-needed funding to make education more accessible and impactful. These grants often come from federal, provincial, and local governments, as well as non-profit organizations, helping to remove financial barriers and promote learning and growth across Canada.
          </Text>

          <Text style={styles.subheading}>Types of Education Grants Available</Text>
          <Image style={styles.image} source={require("../../../assets/blogs/EducationGrants/education_grants2.png")} />
          <Text style={styles.paragraph}>
            A wide range of grants is available to support both students and educators in Canada. For students, the Canada Student Grants (CSG) program is a key source of financial aid. The CSG offers various grants based on factors like income level, academic status, and special needs. For full-time students from low- to middle-income families, this grant can provide up to $4,200 per academic year. Part-time students can receive up to $2,520, and students with permanent disabilities may be eligible for additional funding.
          </Text>
          <Text style={styles.paragraph}>
            There are also several grants available for Indigenous students. The Indigenous Student Bursary, for example, provides financial aid to Indigenous students enrolled in post-secondary programs, helping to offset the costs of tuition and other expenses. Additionally, the Post-Secondary Student Support Program (PSSSP) is a federal initiative that offers financial assistance to First Nations and eligible Inuit students who are pursuing post-secondary education.
          </Text>
          <Text style={styles.paragraph}>
            For those involved in adult learning or skills development, there are grants designed to enhance job skills and employability. The Canada Training Benefit, for instance, offers grants to help Canadians pay for training courses that improve their skills. This can be particularly valuable for adults looking to upgrade their qualifications or transition to a new career.
          </Text>
          <Text style={styles.paragraph}>
            Educators and institutions can also access a variety of grants to support professional development, curriculum innovation, and program enhancement. The Teacher Learning and Leadership Program (TLLP) in Ontario is one example, offering funding to teachers who wish to enhance their teaching practices and develop leadership skills. Similarly, there are grants available for educational institutions aiming to develop new programs or enhance existing ones, such as the Innovation Fund for Educational Excellence.
          </Text>
          <Text style={styles.paragraph}>
            Grants supporting research and educational innovation are also widely available. The Social Sciences and Humanities Research Council (SSHRC) provides funding for research projects that explore a wide range of topics in social sciences and humanities, often focusing on improving educational practices and understanding societal challenges. This is particularly valuable for post-secondary educators and researchers looking to make a significant impact in their field.
          </Text>

          <Text style={styles.subheading}>Applying for Education Grants in Canada</Text>
          <Image style={styles.image} source={require("../../../assets/blogs/EducationGrants/education_grants4.avif")} />
          <Text style={styles.paragraph}>
            Each education grant comes with its own set of requirements and eligibility criteria. Students, for example, need to ensure they meet the financial need requirements, academic status, and sometimes residency or enrollment conditions set by the grant provider. Applications typically require details about the educational program, proof of enrollment, and financial need documentation.
          </Text>
          <Text style={styles.paragraph}>
            Educators and institutions will need to outline their goals, the impact of their proposed project or development, and how the grant will support these objectives. For grants that focus on professional development or program enhancement, a clear plan outlining the outcomes and benefits of the funding is crucial.
          </Text>
          <Text style={styles.paragraph}>
            To find and apply for education grants, it’s helpful to search through government websites, local educational authorities, and non-profit organizations that provide financial aid. Resources like the Government of Canada’s Student Aid website and provincial education ministry pages are great places to start. Additionally, many universities and colleges have financial aid offices that can help guide students and educators through the grant application process.
          </Text>

          <Text style={styles.subheading}>Making the Most of Education Grants</Text>
          <Image style={styles.image} source={require("../../../assets/blogs/EducationGrants/education_grants3.avif")} />
          <Text style={styles.paragraph}>
            Education grants in Canada provide invaluable support for students, teachers, and institutions. Whether covering tuition costs, funding professional development, or enabling research projects, these grants can make a lasting impact on education and career opportunities. For students, accessing these funds can make higher education more attainable, while for educators, grants can foster growth and innovation in teaching practices.
          </Text>
          <Text style={styles.paragraph}>
            The key to success is thorough research, understanding eligibility, and crafting a well-prepared application that demonstrates the value and impact of the grant funding. With the right grant, students and educators alike can achieve their educational goals and contribute to the growth of Canada’s learning community.
          </Text>
          <Text style={styles.paragraph}>
            Explore the opportunities available to you today, and take advantage of the grants that can help you or your institution thrive in 2024. For more information on Canadian grants, including various funding opportunities that may be relevant to education, visit 
            <Text style={styles.link} onPress={() => Linking.openURL('https://www.findgrant.ca')}> FindGrant.ca</Text>. 
            This site offers an AI-powered matching tool that connects users with available grants across multiple sectors, potentially aiding those in educational fields in finding relevant funding options.
          </Text>
        </View>
        <Footer/>
      </ScrollView>
    </>
    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f4f4f4',
    // paddingTop: 20,
  },
  headerContainer: {
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',

    // padding: 15,
    // marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
  },
  contentContainer: {
    padding: 15,
    backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  subheading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
    color: '#011F6F',
    marginTop: 30,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 10,
    color: '#333',
    color: '#011F6F',
    textAlign: 'justify',
  },
  link: {
    color: '#2d3e50',
    textDecorationLine: 'underline',
  },
  image: {
    width: '95%',
    height: screenWidth < 700  ? 350: 500,
    borderRadius: 10,
    marginVertical: 10,
    alignSelf: 'center',
    // resizeMode: "contain"
  },
});
