import { StyleSheet, View, Text } from "react-native";
import React from "react";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import Layout from "../components/Layout.jsx";
import AboutContent from "../components/AboutUsContent.jsx";
import HeroSimpleTitle from "../components/HeroSimpleTitle.jsx";

const AboutUs = () => {
  return (
    <Layout>
      <Header />
      <HeroSimpleTitle title="About Us" subtitle="Explore the essence of our vision and the journey of our team, united in purpose and passion." />      
      <AboutContent />
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({  
});

export default AboutUs;
