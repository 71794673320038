import { Linking, StyleSheet, Text, View, Dimensions } from 'react-native'
import React from 'react'
import { Image } from 'react-native-web'

const screenWidth = Dimensions.get('window').width

const AboutContent = () => {

  const handleEmailPress = () => {
    Linking.openURL("mailto:findgrantca@gmail.com")

  }

  return (
    <View style={styles.container}>
      {/* Vision */}
      <View style={styles.divider}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>Our Vision</Text>
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.text}>At FindGrant, our vision is to empower businesses with cutting-edge software solutions that drive innovation and growth. We believe in a future where technology is accessible, user-friendly, and tailored to the needs of businesses of all sizes. Our focus on innovation, collaboration, and advanced technology ensures that we stay ahead of the curve in the rapidly-evolving software industry. With our expertise in AI, we aim to drive the next wave of digital transformation and help businesses reach their full potential.
            <br /><br />
            Let&#39;s talk.
            <br />
            <Text style={styles.info} onPress={handleEmailPress}>findgrantca [ @ ] gmail.com</Text>
          </Text>
        </View>        
      </View>
      
      {/* Teams */}
      <View style={styles.divider}>
        <View style={styles.teamStory}>
          <View style={styles.teamStoryContainer}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>Team Story</Text>
            </View>
            <View style={styles.contentContainer}>
              <Text style={styles.text}>At FindGrant, we are a team of tech enthusiasts passionate about solving complex business problems through innovative software solutions. With a collaborative and agile approach, we work closely with our clients to understand their unique business needs and deliver tailored solutions that exceed expectations. Our team is comprised of industry veterans in software engineering, and AI, who are always pushing the boundaries of what&#39;s possible. Join us on our journey to revolutionize the software industry.</Text>
            </View>
          </View>
          <View style={styles.Image_container}>
            <Image style={styles.Image} resizeMode="cover" resizeMethod="resize" source={require("../../assets/HeroGrants/AboutUs.jpg")}/>
          </View>
        </View>
      </View >
    </View >

  )
}

export default AboutContent

const styles = StyleSheet.create({
  container: {
    width: screenWidth < 580  ? "70%" : screenWidth < 881  ? "70%" : screenWidth < 1080  ? "70%" : screenWidth < 1343  ? "74%" : "70%",
    display: "flex",
    marginBottom:  screenWidth < 580  ? 30 : screenWidth < 881  ? 40 : screenWidth < 1080  ? 40 : screenWidth < 1343  ? 30 : 60,
    marginTop: screenWidth < 580  ? 40 : screenWidth < 881  ? 40 : screenWidth < 1080  ? 50 : screenWidth < 1343  ? 50 : 50,
    justifyContent: "center",
  },
  divider: {
    alignItems: "center",   
    marginBottom: screenWidth < 580  ? 60 : screenWidth < 881  ? 70 : screenWidth < 1080  ? 70 : screenWidth < 1343  ? 80 : 80,
  },
  contentContainer: {
    marginBottom: 20,
  },
  titleContainer: {
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: screenWidth < 700  ? '6.24vw' : screenWidth < 821  ? '5.24vw': screenWidth < 1080  ? '4.24vw' : 50,
    fontWeight: 700,
    color: "#011F6F",
    borderBottomWidth: 5,
    borderBottomColor: "#DDD",
    lineHeight: screenWidth < 700  ? 38.02 : screenWidth < 881  ? 58.02: screenWidth < 1080  ? 78.02 : 55,
    marginBottom: 16,
  },
  text: {
    fontFamily: "OpenSans-Regular",
    color: "#212529",
    fontSize: screenWidth < 700  ? 16 : 22,
    textAlign: 'justify',
  },
  info: {
    fontWeight: 700,
  },  
  teamStory: {
    width: "100%",
    backgroundColor: "#FFF",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: screenWidth < 881  ? 'column' : screenWidth < 900  ? 'row-reverse': screenWidth < 1080  ? 'row-reverse' :'row-reverse', 
    flexShrink: 0,
    flexWrap: "wrap",    
  },
  teamStoryContainer: {
    width: screenWidth < 881  ? '100%' : screenWidth < 940  ? '100%': screenWidth < 1080  ? '52%' :'52%',
    // backgroundColor: 'blue',
  },
  Image_container: {
    width:  screenWidth < 881  ? '100%' : screenWidth < 1000  ? '100%': '40%',
    height: screenWidth < 700  ? 200 : screenWidth < 881  ? 350 : screenWidth < 1000  ? 350 : screenWidth < 1080  ? 350 : 350,
    marginTop: screenWidth < 700  ? 20 : screenWidth < 881  ? 40 : screenWidth < 1000  ? 60 : screenWidth < 1080  ? 8 : 8,
    flexShrink: 0,
    borderRadius: 20,
    overflow: "hidden",
    // Shadows (iOS)
    shadowColor: "rgba(0, 0, 0, 0.25)",
    elevation: 5,
  },
  Image: {
    width: "100%",
    height: "100%",
  },
  contentContainer: {
    color: "#222222",
    fontSize: screenWidth < 700  ? '2.4vh' : screenWidth < 881  ? '2.6vw': screenWidth < 1080  ? '1.6vw' :'1.6vw',
    fontFamily: "OpenSans-Regular",
    fontWeight: 600,
    lineHeight: 32.6,
    marginBottom: 32,
  },

})