import React, { useState, useEffect } from "react";
import { View, Text } from "react-native-web";
import {
  Image,
  Pressable,
  StyleSheet,
  Linking,
  Dimensions,
} from "react-native";
import { useRoute } from "@react-navigation/native";
import flagIcon from "../../assets/flagIcon.png";
import SaveIcon from "../components/SaveIcon";
import IssueReported from "../components/reportIssueComponents/IssueReported";
import ReportIssue from "../components/ReportIssue";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import Layout from "../components/Layout.jsx";

const screenWidth = Dimensions.get("window").width;

const GrantPageDetails = () => {
  const route = useRoute();
  const { short_url } = route.params;
  // const { short_url } = route.params;
  const [programDetails, setProgramDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isReportIssueVisible, setReportIssueVisible] = useState(false);
  const [isIssueReportedVisible, setIssueReportedVisible] = useState(false);

  useEffect(() => {
    const fetchProgramDetails = async () => {
      try {
        const response = await fetch(
          `https://api.findgrant.ca/webpage/by-short-url/${short_url}`
        );
        if (!response.ok) {
          throw new Error(`Error getting data: ${response.statusText}`);
        }
        const data = await response.json();
        setProgramDetails(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching program details:", err);
        setError("Could not load program information.");
        setLoading(false);
      }
    };

    if (short_url) {
      fetchProgramDetails();
    }
  }, [short_url]);

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>Loading Data...</Text>
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{error}</Text>
      </View>
    );
  }

  const handleReportIssue = () => {
    setReportIssueVisible(true);
  };

  const handleSubmitIssue = () => {
    setReportIssueVisible(false);
    setIssueReportedVisible(true);
  };

  const handleCloseIssueReported = () => {
    setIssueReportedVisible(false);
  };
  return (
    <Layout>
      <Header />
      <View style={styles.container}>
        {/* Bread crumbs */}
        <View>
          <Text>Bread Crumbs</Text>
        </View>

        {/* Grant Details */}
        <View style={styles.headerTitle}>
          <View style={styles.saveIcon}>
            <SaveIcon />
          </View>
          <View style={styles.titles}>
            <Text style={styles.title}>{programDetails.programName}</Text>
            <Text style={styles.textTitle}>
              <strong>
                Program source:{" "}
                {programDetails.programSource || "Not Specified"}
              </strong>
              <br />
              <br />
            </Text>
          </View>
        </View>

        <View style={styles.buttonStyleApply}>
          <Pressable
            style={styles.buttonApplyNow}
            onPress={() => {
              const url = `https://admin-api.findgrant.ca/s/${programDetails.short_urls}?-${programDetails.slug}`;
              Linking.openURL(url).catch((err) =>
                console.error("Link could not open:", err)
              );
            }}
          >
            <Text style={styles.buttontextApplyNow}>Apply Now</Text>
          </Pressable>
        </View>

        {/* Info */}
        <View style={styles.information}>
          <View style={styles.descrpNdButton}>
            <View style={styles.description}>
              <Text style={styles.subTitle}>Program Description</Text>
              <Text style={styles.text}>
                {programDetails.description || "Not Specified"}
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Status:</strong>{" "}
                {programDetails.programStatus || "Not Specified"}
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Deadline:</strong> N/A
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Open Date:</strong>{" "}
                {programDetails.openDate || "Not Specified"}
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Close Date:</strong>{" "}
                {programDetails.closeDate || "Not Specified"}
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Funding amount:</strong>{" "}
                {programDetails.totalFundingAmount || "Not Specified"}
              </Text>
              <Text style={styles.text}>
                <strong style={styles.mainText}>Unit:</strong>{" "}
                {programDetails.unit || "Not Specified"}
              </Text>
            </View>
          </View>
          <View style={styles.apply}>
            <View style={styles.availability}>
              <Text style={styles.subTitle}>Who can Apply</Text>
              <Text style={styles.WomensAvailability}>
                <strong style={styles.mainText}>Available for:</strong>{" "}
                {programDetails.targetAudience || "Not Specified"}
              </Text>
            </View>
            <View style={styles.location}>
              <Text style={styles.WomensAvailability}>
                <strong style={styles.mainText}>Location: </strong>{" "}
                {programDetails.location || "Not Specified"}
              </Text>
            </View>
            <View style={[styles.text, styles.types]}>
              <Text style={styles.WomensAvailability}>
                <strong style={styles.mainText}>Types of industry:</strong>
                {programDetails.mainIndustry || "Not Specified"}
              </Text>
            </View>
          </View>
          <View style={styles.applndButton}>
            <View style={styles.application}>
              <Text style={styles.subTitle}>Steps for Application</Text>
              <Text style={styles.text}>N/A</Text>
            </View>

            <View style={styles.buttonStyleApply_bottom}>
              <Pressable
                style={styles.buttonApplyNow}
                onPress={() => {
                  const url = `https://admin-api.findgrant.ca/s/${programDetails.short_urls}?-${programDetails.slug}`;
                  Linking.openURL(url).catch((err) =>
                    console.error("Link could not open:", err)
                  );
                }}
              >
                <Text style={styles.buttontextApplyNow}>Apply Now</Text>
              </Pressable>
            </View>

            {/* Report Button */}
            <View style={styles.reportBtn}>
              <Pressable
                style={styles.buttonReport}
                onPress={handleReportIssue}
              >
                <Text style={styles.buttontextReport}>
                  <Image source={flagIcon} style={styles.icon} />
                  Report
                </Text>
              </Pressable>
            </View>
          </View>
        </View>

        {isReportIssueVisible && (
          <ReportIssue
            programId={programDetails.programId}
            visible={isReportIssueVisible}
            onClose={() => setReportIssueVisible(false)}
            onSubmit={handleSubmitIssue}
          />
        )}

        {isIssueReportedVisible && (
          <IssueReported
            visible={isIssueReportedVisible}
            onClose={handleCloseIssueReported}
          />
        )}
      </View>
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "80%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  headerTitle: {
    width: "100%",
    paddingTop: 25,
    paddingBottom: 25,
    borderBottomWidth: 3,
    borderColor: "#011F6F",
  },
  textTitle: {
    fontFamily: "OpenSans-Regular",
    fontSize: 16,
    color: "#011F6F",
    paddingTop: 40,
  },
  information: {},
  descrpNdButton: {
    borderBottomWidth: 1,
    borderColor: "#EBEBEB",
    paddingTop: 20,
    paddingBottom: 30,
    width:
      screenWidth < 580
        ? "100%"
        : screenWidth < 881
        ? "100%"
        : screenWidth < 1080
        ? "100%"
        : screenWidth < 1343
        ? "100%"
        : "80%",
  },
  apply: {
    paddingTop: "25px",
    paddingBottom: "25px",
    borderBottomWidth: "1px",
    borderColor: "#EBEBEB",
  },
  saveIcon: {
    position: "absolute",
    right: 0,
    top:
      screenWidth < 580
        ? 40
        : screenWidth < 881
        ? 40
        : screenWidth < 1080
        ? 40
        : screenWidth < 1343
        ? 40
        : 40,
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize:
      screenWidth < 580
        ? 30
        : screenWidth < 881
        ? 45
        : screenWidth < 1080
        ? 45
        : screenWidth < 1343
        ? 48
        : 48,
    textDecorationLine: "underline",
    color: "#011f6f",
    width:
      screenWidth < 580
        ? "90%"
        : screenWidth < 881
        ? "91%"
        : screenWidth < 1080
        ? "94%"
        : screenWidth < 1343
        ? "94%"
        : "96%",
  },
  subTitle: {
    fontFamily: "OpenSans-Regular",
    fontWeight: 700,
    fontSize:
      screenWidth < 580
        ? 20
        : screenWidth < 881
        ? 22
        : screenWidth < 1080
        ? 26
        : screenWidth < 1343
        ? 26
        : 26,
    lineHeight: "32.68px",
    color: "#011f6f",
    paddingBottom: 30,
  },
  text: {
    fontFamily: "OpenSans-Regular",
    fontSize: 18,
    color: "#222222",
    paddingBottom: 20,
    // lineHeight: 25,
  },
  mainText: {
    fontFamily: "OpenSans-Regular",
    fontSize: 16,
    color: "#011F6F",
    paddingRight: "10px",
    paddingBottom: "12px",
  },
  buttonStyleApply: {
    marginLeft: "auto",
    // marginRight: 40,
    marginTop: 40,
  },
  buttonStyleApply_bottom: {
    marginLeft: 0,
    marginTop: 30,
    marginBottom: 20,
    width: 180,
  },
  buttonApplyNow: {
    paddingVertical: 10,
    paddingHorizontal: 40,
    backgroundColor: "#F5BF4C",
    borderRadius: 30,
  },
  buttontextApplyNow: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans-Regular",
    textAlign: "center",
  },
  reportBtn: {
    marginLeft: "auto",
    marginBottom: 20,
  },
  buttonReport: {
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    borderWidth: "1px",
    borderColor: "#777777",
    borderRadius: 30,
    flexDirection: "row",
  },
  buttontextReport: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans-Regular",
    color: "#777777",
    paddingLeft: "4px",
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 15,
    marginBottom: -2,
  },
  WomensAvailability: {
    fontSize: 18,
    marginBottom: "5px",
    // paddingLeft: "108px",
  },

  location: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  types: {
    flexDirection: "row",
  },
  companyTypes: {
    fontFamily: "OpenSans-Regular",
    fontSize: "16px",
    // marginTop: 0,
    marginBottom: "5px",
    // paddingLeft: "142px",
  },
  applndButton: {
    paddingTop: "25px",
  },
});

export default GrantPageDetails;
