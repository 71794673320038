import { View, StyleSheet, Text } from "react-native";
import { CheckBox } from "react-native-web";
import { useState } from "react";

const AllGrantButton=()=>{


  const [isChecked, setIsChecked] = useState(false);

  return (
    <View style={styles.OuterContainer}>
      <View style={styles.InnerContainer}>
        <CheckBox
            value={isChecked}
            onValueChange={setIsChecked}
        />
        <Text style={styles.text}>All Grants</Text>
      </View>
    </View>
    )

}

const styles=StyleSheet.create({
    OuterContainer:{
        width: 140,
        height: 60,
        borderRadius: 70,  
        backgroundColor: "rgb(0,32,113)",        
    },
    InnerContainer:{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,    
        alignItems: 'center',
        justifyContent: 'center',
    },
    text:{
        textAlign: 'center',
        fontSize: 18,
        color: "#FFFFFF",
        marginLeft: 10,
    }
})

export default AllGrantButton;