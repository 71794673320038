import "@expo/metro-runtime";
import React from "react";
import { View, Text, StyleSheet, Image, Dimensions } from "react-native";
import RadialGradientCircle from "./heroComponents/GradientRadialCircle";

const heroData = {
  women: {
    title: "Grants for Women-Owned Businesses",
    description:
      "Navigating business grants can be challenging for women entrepreneurs. We specialize in Canadian business grants, offering guidance on applications and access to first-time owner grants, scholarships for women, federal grants, personal government grants, and small business grants.\n\nLet us help streamline the application process and identify the best funding opportunities to empower you in achieving your business goals.",
    imageSrc: require("../../assets/HeroGrants/Hero-Grants-Women.png"),
  },
  artCulture: {
    title: "Grants for Art & Culture Business",
    description:
      "Unleash your creative potential with FindGrant's extensive range of funding opportunities for the arts and culture sector in Canada. Whether you're an artist, performer, or cultural organization, we help you navigate the myriad of grants available to support your creative projects and artistic initiatives.\n\nDiscover federal and provincial arts grants, funding for cultural festivals, artist residencies, and more. FindGrant is your gateway to securing financial support for artistic innovation, cultural preservation, and community engagement, empowering you to bring your creative vision to life and enrich Canada's diverse cultural landscape.",
    imageSrc: require("../../assets/HeroGrants/ArtCulture.png"),
  },
  indigenous: {
    title: "Grants for Indigenous Entrepreneurs",
    description:
      "FindGrant is dedicated to supporting Indigenous entrepreneurs across Canada by providing tailored funding opportunities. Discover federal grants, startup resources, and business expansion funding designed to promote economic development, cultural preservation, and community empowerment.\n\nExplore initiatives like business development grants, capacity-building grants, and cultural heritage grants to foster innovation and prosperity in Indigenous communities.",
    imageSrc: require("../../assets/HeroGrants/Indigneous.png"),
  },
  blackEntrepreneur: {
    title: "Grants for Black-owned businesses",
    description:
      "Discover an extensive range of funding opportunities specifically designed for black and BIPOC entrepreneurs across Canada. FindGrant connects you with essential grants, offering federal support, startup resources, and business growth initiatives tailored to the unique needs of Black-owned and BIPOC-owned businesses.\n\nExplore federal grants for black-owned businesses, startup grants, and more, aimed at fostering cultural empowerment, innovation, and economic development within these vibrant communities.",
    imageSrc: require("../../assets/HeroGrants/BlackEntrepeneur.png"),
  },
  startUp: {
    title: "Grants for Start-up Entrepreneurs",
    description:
      "Access a diverse spectrum of startup funding opportunities tailored for new entrepreneurs grants available across Canada. FindGrant offers insights into securing a variety of funding, emphasizing government startup business grants and small business grants for startups, alongside the sought-after Canada business startup grants.\n\nOur mission is to ensure that entrepreneurs from all sectors have the financial support needed to launch and grow their enterprises. With a focus on national and provincial startup grants, FindGrant provides a streamlined guide to navigating the entrepreneurial ecosystem, equipping you with the necessary resources to successfully embark on your business journey.",
    imageSrc: require("../../assets/HeroGrants/StartUp.png"),
  },
  environmental: {
    title: "Grants for \nEco-Business",
    description:
      "Seeking to positively impact the environment while growing your business? Look no further! FindGrant is your gateway to a diverse range of environmental business grants available across Canada.\n\nWhether you dream of greener home grants, electric vehicle business grants, solar grants for businesses, government grants for green business start-ups, or even EV charging station grants, we can help you find the perfect funding match.",
    imageSrc: require("../../assets/HeroGrants/Environment.png"),
  },
  smallBusiness: {
    title: "Grants for Small Business",
    description:
      "Explore a wide variety of funding opportunities, from federal small business grants to specialized startup and innovation funding. With FindGrant, discover government grants, non-repayable grants, and small business loans and grants tailored to a range of industries including technology, agriculture, and more.\n\nWe’re focused on empowering entrepreneurs by providing quick access to essential financial resources, including small business innovation grants and digital transformation grants, to help your business thrive in a competitive marketplace. Leverage FindGrant to navigate the landscape of financial assistance and unlock the potential of your small business with the right funding support.",
    imageSrc: require("../../assets/HeroGrants/SmallBusiness.png"),
  },
  technology: {
    title: "Grants for Technology & Innovation Business",
    description:
      "Canada is a hub of innovation, and FindGrant can assist you in tapping into its full potential and boosting your business technology grant. We connect businesses of all sizes with the Canadian research and development grants you need to turn your ideas into reality. Our comprehensive database encompasses a wide range of tech grants in Canada, including those for clean energy grants, artificial intelligence grants, government grants for cybersecurity, and blockchain grants.\n\nWhether you're a tech start-up seeking seed funding or an established enterprise aiming to scale your operations, with our personalized AI-powered matching and expert assistance, finding the perfect technology grants in Canada is easier than ever.",
      imageSrc: require("../../assets/HeroGrants/Technology.png"),
  },
  contactUs: {
    title: "Contact Us",
    description:
      "\n\nWelcome to our Contact Us page,\nwhere reaching out to us is just a click away.",
      imageSrc: require("../../assets/HeroGrants/ContactUs.png"),
  },
  AboutUs: {
    title: "About Us",
    description:
      "\n\nExplore the essence of our vision and the journey of our team, united in purpose and passion.",
      imageSrc: require("../../assets/HeroGrants/AboutUs.png"),
  },
};



const screenWidth = Dimensions.get("window").width;

const Hero = ({ category }) => {
  const { title, description, imageSrc } = heroData[category];
  const isSpecialCategory = category === "AboutUs" || category === "contactUs";
  return (
    <View
      style={[styles.containerHero, isSpecialCategory && styles.specialContainer]}
    >
      {/* // Text */}
      <View
        style={[
          styles.Text_Container,
          isSpecialCategory && styles.specialTextContainer,
        ]}
      >
        <View style={styles.titleContainer} >
          <Text
            style={[
              styles.Text_Main,
              isSpecialCategory && styles.specialTextMain,
              {
                borderBottomWidth:  isSpecialCategory ? 3 : null,
                borderBottomColor: isSpecialCategory ? "#011f6f" : "#000000",
                width: isSpecialCategory ? null : screenWidth < 881  ? screenWidth * .9 :  screenWidth * 0.38,
              }
            ]}
          >
            {title}
          </Text>
        </View>
        <Text
          style={[
            styles.Text_small,
            isSpecialCategory && styles.specialTextSmall,
          ]}
        >
          {description}
        </Text>
      </View>
      {/* // Images */}
      <View style={styles.right_panel}>
        <View style={styles.Home_Hero_Image_container}>
          <Image
            source={imageSrc}            
            resizeMode="contain"
            resizeMethod="resize"
            style={styles.Home_Hero_Image}
          />
        </View>
        {/* Background Radial Gradients */}
        <RadialGradientCircle
          key={`${category}-yellow-gradient`}
          uniqueId={`${category}-yellow`}
          size={screenWidth < 580  ? 400 : screenWidth < 881  ? 600 : screenWidth < 1080  ? 300 : screenWidth < 1343  ? 500 : 650}
          colors={["#F5BF4C", "#FFF"]}
          style={styles.backgroundYellowEllipse}
        />
        <RadialGradientCircle
          key={`${category}-blue-gradient`}
          uniqueId={`${category}-blue`}
          size={screenWidth < 580  ? 400 : screenWidth < 881  ? 600 : screenWidth < 1080  ? 300 : screenWidth < 1343  ? 500 : 650}
          colors={["#4066CD", "#FFF"]}
          style={styles.backgroundBlueEllipse}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerHero: {
    width: "85%",
    backgroundColor: "#FFF",
    textAlign: "left",
    alignItems: 'flex-start',    
    justifyContent: "space-between",    
    flexDirection: screenWidth < 881  ? 'column' : screenWidth < 900  ? 'row': screenWidth < 1080  ? 'row' :'row',                
    paddingBottom: screenWidth < 580  ? 50 : screenWidth < 881  ? 50 : screenWidth < 1080  ? 20 : screenWidth < 1343  ? 40 : 20,  
    // backgroundColor: "red",   
    // gap: screenWidth < 700  ? null : screenWidth < 881  ? null: screenWidth < 1080  ? '3vw' : screenWidth < 1262  ? '3vw' : '8vw',    
    // paddingHorizontal: screenWidth < 700  ? '3vw' : screenWidth < 821  ? '3vw': screenWidth < 1057  ? '3vw' :'4vw', // Replaces marginLeft and marginRight
  },
  Text_Container: {
    width: screenWidth < 881  ? '100%' : screenWidth < 1080  ? '55%' : screenWidth < 1262  ? '55%' : '50%',
    paddingTop: screenWidth < 881  ? 40 : screenWidth < 1000  ? 54 : screenWidth < 1080  ? 54 : 64,
    paddingBottom: screenWidth < 881  ? null : screenWidth < 1000  ? 64 : screenWidth < 1080  ? 64 : screenWidth < 1262  ? 64 : 64,
    paddingHorizontal: 0,
    marginLeft: screenWidth < 700  ? '4.24vw' : screenWidth < 821  ? '4.24vw': screenWidth < 1080  ? '2vw' :'1vw',
    marginRight: 'vw',
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  titleContainer: {
    flexWrap: "wrap",
    alignSelf: 'flex-start',
  },
  Text_Main: {
    color: "#011F6F",
    fontSize: screenWidth < 700  ? '6.24vw' : screenWidth < 821  ? '5.24vw': screenWidth < 1080  ? '4vw' :'3.8vw',
    fontFamily: "Montserrat-Bold",
    fontWeight: 700,
    lineHeight: screenWidth < 700  ? 38.02 : screenWidth < 881  ? 58.02: screenWidth < 1080  ? 78.02 :78.02,
    marginBottom: 16,
    alignSelf: 'flex-start',
  },
  Text_sub: {
    color: "#222222",
    fontSize: screenWidth < 700  ? '2.4vh' : screenWidth < 881  ? '2.6vw': screenWidth < 1080  ? 22 : 24,
    fontFamily: "OpenSans-Regular",
    fontWeight: 600,
    lineHeight: 32.6,
    marginBottom: 32,
  },
  Text_small: {
    color: "#222222",
    fontSize: 16,
    fontFamily: "OpenSans-Regular",
    fontWeight: 400,
    lineHeight: 21.79,
  },
  right_panel: {
    position: "relative",
    overflow: "visible",
    alignItems: "flex-start",
    width: screenWidth < 580  ? '100%' : screenWidth < 881  ? '100%' : screenWidth < 1080  ? '35vw' : screenWidth < 1343  ? '33vw' : 'auto',
    // backgroundColor: "yellow",
  
  },
  Home_Hero_Image_container: {
    width:  screenWidth < 881  ? '84vw' : screenWidth < 1000  ? '33vw': screenWidth < 1080  ? 372 :'33vw',
    height: screenWidth < 700  ? 200 : 350,
    marginTop: screenWidth < 881  ? 40 : screenWidth < 1000  ? 90 : screenWidth < 1080  ? 90 : 90,
    marginBottom: screenWidth < 881  ? 5 : screenWidth < 1000  ? null : screenWidth < 1080  ? null : null,
    marginLeft: screenWidth < 881  ? '4vw' : screenWidth < 1000  ? '1vw': screenWidth < 1262  ? '4vw' :'3vw',
    marginRight: screenWidth < 881  ? '3vw' : screenWidth < 1000  ? '3vw': screenWidth < 1262  ? '1vw' :'3vw',
    flexShrink: 0,
    borderRadius: 20,
    overflow: "hidden",
    // Shadows (iOS)
    shadowColor: "rgba(0, 0, 0, 0.25)",
    elevation: 5,
  },
  Home_Hero_Image: {
    width: "100%",
    height: "100%",        
  },   
  backgroundYellowEllipse: {
    position: "absolute",
    top: screenWidth < 580  ? -60 : screenWidth < 881  ? -80 : screenWidth < 1000  ? -5 : screenWidth < 1343  ? -55 : -105,  
    left: screenWidth < 580  ? -100 : screenWidth < 881  ? -150 : screenWidth < 1000  ? -80 :  screenWidth < 1343  ? -140 : -210,
    zIndex: -1,
    opacity: "0.6",
  },
  backgroundBlueEllipse: {
    position: "absolute",
    top: screenWidth < 580  ? 15 : screenWidth < 881  ? 70 : screenWidth < 1000  ? 120 : screenWidth < 1343  ? 100 : -5,  
    left: screenWidth < 580  ? 70 : screenWidth < 881  ? 155 : screenWidth < 1000  ? 110 : screenWidth < 1343  ? 5 : 70 ,
    zIndex: -2,
    opacity: "0.6",
  },
});

export default Hero;
