import React from "react";
import { View, Text, Image, StyleSheet, Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;

const Step = ({
  stepNumber,
  iconSource,
  description,
  isLastStep,
  isTextFirst,
}) => {
  return (
    <View style={[styles.step, !isTextFirst ? styles.rowReverse : null]}>
      <View style={styles.stepContent}>
        <Text style={styles.stepDescription}>{description}</Text>
      </View>
      <View style={styles.stepIconContainer}>
        <View style={styles.circle}>
          <Text style={styles.circleText}>{stepNumber}</Text>
        </View>
        {!isLastStep && <View style={styles.verticalLine}></View>}
      </View>
      <View style={styles.imgContainer}>
        <Image source={iconSource} style={styles.icon} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  step: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  rowReverse: {
    flexDirection: "row-reverse",
  },
  stepIconContainer: {
    justifyContent: "center",
    alignItems: "center",

    position: "relative",
    width: "20%",
  },
  circle: {
    width: 70,
    height: 70,
    borderRadius: 700,
    backgroundColor: "#011F6F",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  circleText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  imgContainer: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  verticalLine: {
    position: "absolute",
    top: 40,
    width: 5,
    height: 180,
    backgroundColor: "#011F6F",
    zIndex: 0,
  },
  icon: {
    width: screenWidth < 881  ? 150 : screenWidth < 1261  ? 200 : screenWidth < 1080  ? 200 : 200,
    height: screenWidth < 881  ? 150 : screenWidth < 1261  ? 200 : screenWidth < 1080  ? 200 : 200,
  },
  stepContent: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepDescription: {
    fontFamily: "OpenSans-Regular",
    fontSize: screenWidth < 881  ? '2vh' : screenWidth < 1261  ? 24 : screenWidth < 1080  ? 24 : 24,
    color: "#000",
    textAlign: "center",
  },
});

export default Step;
