import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const DropdownFooter = () => {
  return (
    <View style={styles.DropdownFooterContainer}>
      <Text style={styles.textTitle}>Grant for Diverse Communities</Text>
      <Text style={styles.text}><a href="/IndigenousGrants" style={styles.Link}>Indigenous Grants</a></Text>
      <Text style={styles.text}><a href="/StartUpGrants" style={styles.Link}>Start-Up Grants</a></Text>
      <Text style={styles.text}><a href="/BlackEntrepreneurshipGrants" style={styles.Link}>Black Entrepreneurship Grants</a></Text>
      <Text style={styles.text}><a href="/EnvironmentalGrants" style={styles.Link}>Environmental Business Grants</a></Text>
      <Text style={styles.text}><a href="/TechnologyInnovationGrants" style={styles.Link}>Technology & Innovation Grants</a></Text>
      <Text style={styles.text}><a href="/ArtCultureGrants" style={styles.Link}>Art & Culture</a></Text>
      <Text style={styles.text}><a href="/SmallBusinessGrants" style={styles.Link}>Small Business Grants</a></Text>
      <Text style={styles.text}><a href="/WomanOwnedBusinessGrants" style={styles.Link}>Woman-Owned Business Grants</a></Text>
    </View>
  )
}

export default DropdownFooter

const styles = StyleSheet.create({
  text: {
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: 16,
    fontFamily: "OpenSans-Regular",
  },
  DropdownFooterContainer: {
    // width: 360,
    display: "flex",
    alignItems: "center",
    alignItems: "left",
    // paddingTop: 100

  },
  textTitle: {
    color: "white",
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 16,
    fontFamily: "OpenSans-Regular",
  },
  Link: {
    textDecorationLine: "none",
    color: "#FFFFFF"
  }
})