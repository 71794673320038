import "@expo/metro-runtime";
import React, {useEffect, useState} from "react";
import { View, Text, StyleSheet, Image, Pressable, Dimensions } from "react-native";
import GradientRadialCircle from "./heroComponents/GradientRadialCircle";
import { useNavigation, useRoute } from "@react-navigation/native";
import SearchButton1 from "./SearchBarComponents/SearchButton1";
import ContactButton1 from "./SearchBarComponents/ContactButton1";

const { width } = Dimensions.get("window"); // Get the screen width
const screenWidth = Dimensions.get('window').width;

const Hero = () => {
  const navigation = useNavigation();
  const route = useRoute();

  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = async () => {
    console.log("Navigating with values:", selectedValues); // verify
    //navigation.navigate("ProgramSearch", { selectedValues }); // Navigation and data passage
    const { location, community, industry, grants } = selectedValues;
    if (!location && !community && !industry && !grants) {
      setErrorMessage("You must select at least one attribute.");
    } else {
      setErrorMessage(""); // Limpia el mensaje de error
      navigation.navigate("ProgramSearch", { selectedValues });
    }
  };

  return (
    <View style={styles.container}>
      {/* // Text */}
      <View style={styles.Text_Container}>
        <Text style={styles.Text_Main}>
          Government Grants for your Business
        </Text>
        <Text style={styles.Text_sub}>
          Discover Funding Opportunities Across Canada with our AI Agent
        </Text>
        <Text style={styles.Text_small}>
          FindGrant is your gateway to diverse funding opportunities across
          Canada, including small business, research, sustainability, and BIPOC
          grants.
        </Text>
        <View style={styles.button_Container}>
          <SearchButton1 onPress={() => navigation.navigate("ProgramSearch" )}/>
          <ContactButton1 onPress={() => navigation.navigate("Contact" )}/>
          {/* <Pressable
            style={[styles.button_Search, { zIndex: 100 }]}
            onPress={() => navigation.navigate("ProgramSearch" )}
            >
            <Text style={styles.button_Search_Text}>Search now</Text>
          </Pressable>
          <Pressable
            style={[styles.button_Search, styles.button_Contact]}>
            <Text
              style={[styles.button_Search_Text, styles.button_Contact_Text]}
            ><a href="/contactUs" style={styles.links}>Contact us</a>
            </Text>
          </Pressable> */}
        </View>
      </View>
      {/* // Images */}
      <View style={styles.right_panel}>
        <View style={styles.Home_Hero_Image_container}>
          <Image
          source={require("../../assets/Home-Hero-girl-photo.jpg")}
          // resizeMode="cover"
          style={styles.Home_Hero_Image}
        />
        </View>
        {/* Background Radial Gradients */}
        <GradientRadialCircle
          key="yellow-gradient"
          uniqueId="yellow"
          size={800}
          colors={["#F5BF4C", "#FFF"]}
          style={styles.backgroundYellowEllipse}
        />
        <GradientRadialCircle
          key="blue-gradient"
          uniqueId="blue"
          size={800}
          colors={["#4066CD", "#FFF"]}
          style={styles.backgroundBlueEllipse}
        />
      </View>      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    backgroundColor: "#FFF",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "left",
    flexDirection: screenWidth < 881  ? 'column' : screenWidth < 900  ? 'row': screenWidth < 1080  ? 'row' :'row',         
    gap: screenWidth < 700  ? null : screenWidth < 881  ? null: screenWidth < 1080  ? '0.5vw' :'10vw',
    flexShrink: 0,    
    flex: 1,
    paddingHorizontal: screenWidth < 700  ? '3vw' : screenWidth < 821  ? '3vw': screenWidth < 1057  ? '3vw' :'4vw', // Replaces marginLeft and marginRight
    // backgroundColor: 'blue',
    paddingBottom: screenWidth < 881  ? 20 : screenWidth < 1000  ? 64 : screenWidth < 1080  ? 0 : screenWidth < 1280  ? 20 : 50,
  },
  Text_Container: {
    width: screenWidth < 881  ? '85vw' : screenWidth < 940  ? 530: screenWidth < 1080  ? 570 :'38vw',
    paddingTop: screenWidth < 881  ? 0 : screenWidth < 1000  ? 64 : screenWidth < 1080  ? 64 : 64,
    // paddingVertical: 64,
    paddingBottom: screenWidth < 881  ? null : screenWidth < 1000  ? 20 : screenWidth < 1080  ? 0 : 0,
    paddingHorizontal: 0,
    marginLeft: screenWidth < 700  ? '4.24vw' : screenWidth < 821  ? '4.24vw': screenWidth < 1080  ? '2vw' :'1vw',
    marginRight: 'vw',
    flexDirection: "column",
    justifyContent: "flex-start",
    // backgroundColor: 'blue',
  },
  Text_Main: {
    color: "#011F6F",
    fontSize: screenWidth < 500  ? "10vw" : screenWidth < 821  ? 54: screenWidth < 1080  ? 56 : 64,
    fontFamily: "Montserrat-Bold",
    fontWeight: 700,
    lineHeight: screenWidth < 700  ? 58.02 : screenWidth < 881  ? 65: screenWidth < 1080  ? 74.02 : 76.02,
    marginBottom: 18,
    flexWrap: "wrap",
    wordWrap: "" 
  },
  Text_sub: {
    color: "#222222",
    fontSize: screenWidth < 700  ? '2.4vh' : screenWidth < 881  ? '2.6vw': screenWidth < 1080  ? 22 : 24,
    fontFamily: "OpenSans-Regular",
    fontWeight: 600,
    lineHeight: 32.6,
    marginBottom: 28,
  },
  Text_small: {
    color: "#222222",
    fontSize: 16,
    fontFamily: "OpenSans-Regular",
    fontWeight: 400,
    lineHeight: 21.79,
  },  
  Home_Hero_Image_container: {
    width:  screenWidth < 881  ? '84vw' : screenWidth < 1000  ? '33vw': screenWidth < 1080  ? 372 :'33vw',
    height: 500,
    marginTop: screenWidth < 881  ? 0 : screenWidth < 1080  ? 80 : screenWidth < 1280  ? 90 : 75,
    marginBottom: screenWidth < 881  ? 5 : screenWidth < 1000  ? null : screenWidth < 1080  ? null : null,
    // marginLeft: 50,
    // marginRight: 40,
    marginLeft: screenWidth < 881  ? '4vw' : screenWidth < 1000  ? '1vw': screenWidth < 1080  ? 0 :'2vw',
    marginRight: screenWidth < 881  ? '3vw' : screenWidth < 1000  ? '3vw': screenWidth < 1080  ? '4vw' :'3vw',
    flexShrink: 0,
    borderRadius: 20,
    backgroundColor: "lightgray",
    overflow: "hidden",
    // Shadows (iOS)
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: .1,
    shadowRadius: 18.7,
    // Shadows (Android)
    elevation: 5,
  },
  Home_Hero_Image: {
    width: "100%",
    height: "100%",
  },
  button_Container: {
    paddingVertical: screenWidth < 881  ? 14 : 64,
    paddingBottom: screenWidth < 580  ? 64 : screenWidth < 881  ? 54 : screenWidth < 1000  ? 34 : screenWidth < 1080  ? 64 : screenWidth < 1343  ? 64 : 64,  
    flexDirection: "row",
    gap: 20,
    // backgroundColor: "red",
  },
  button_Search: {
    backgroundColor: "#F5BF4C",
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  button_Contact: {
    backgroundColor: "#FFF",
    borderWidth: 2,
    borderColor: "#011F6F",
  },
  button_Search_Text: {
    color: "#222",
    fontFamily: "OpenSans-Bold",
    fontSize: 16,
  },
  button_Contact_Text: {
    color: "#011F6F",
    lineHeight: 24,
  },
  right_panel: {
    position: "relative",
    overflow: "visible",
  },
  backgroundYellowEllipse: {
    position: "absolute",
    top: screenWidth < 580  ? -125 : screenWidth < 881  ? -125 : screenWidth < 1000  ? -90 : screenWidth < 1080  ? -95 : screenWidth < 1343  ? -95 : -95,  
    left: screenWidth < 580  ? -200 : screenWidth < 881  ? -190 : screenWidth < 1000  ? 3000 : screenWidth < 1080  ? -240 : screenWidth < 1343  ? -240 : -240,
    zIndex: -1,
    opacity: "0.6",
  },
  backgroundBlueEllipse: {
    position: "absolute",
    top: screenWidth < 580  ? -30 : screenWidth < 881  ? -40 : screenWidth < 1000  ? -60 : screenWidth < 1080  ? 0 : screenWidth < 1343  ? 20 : 20,  
    left: screenWidth < 580  ? -120 : screenWidth < 881  ? 30 : screenWidth < 1000  ? 3000 : screenWidth < 1080  ? 1000 : screenWidth < 1343  ? -105 : 0 ,
    zIndex: -2,
    opacity: "0.6",
  },
  links: {
    textDecorationLine: "none", 
    color: "#011F6F"
  },
});

export default Hero;
