import React from "react";
import { Svg, Path } from "react-native-svg"; // Importa Svg y Path de react-native-svg

const SaveIcon = ({ width = 21, height = 27, color = "#011F6F" }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M1.015 3.00062V3C1.015 1.88824 1.90131 1 3 1H18C19.1052 1 20 1.89478 20 3V25.4835L10.8939 21.5809L10.5 21.412L10.1061 21.5809L1.00095 25.4831L1.015 3.00062Z"
      stroke={color}
      strokeWidth="2"
    />
  </Svg>
);

export default SaveIcon;
