import { View, StyleSheet, Dimensions } from "react-native";
import React from "react";
import { Image } from "react-native-web";
import Navbar from "./headerComponents/Navbar";

const screenWidth = Dimensions.get('window').width

const Header = () => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.innerContainer}>
        <a href="/">
        <Image
          style={styles.logo}
          source={require("../../assets/FindGrant Logo.png")}
        />
        </a>
        <Navbar />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    // width: "100vw",
    width: "100%",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 60,
    paddingHorizontal: screenWidth < 700  ? '3vw' : screenWidth < 821  ? '3vw': screenWidth < 1080  ? '2vw' :'4vw', // Replaces marginLeft and marginRight
    height: 70,
    zIndex: 10,
    backgroundColor: "white",
    // backgroundColor: "red",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: screenWidth < 700  ? 'space-between' : screenWidth < 821  ? 'space-between': screenWidth < 1057  ? 'space-between' :'space-between',
    width: "100%", // Ensures inner container fills header width
    // backgroundColor: "blue",
  },
  logo: {
    width: 192,
    height: 39,
  },
});

export default Header;
