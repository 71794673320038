import { Modal, Pressable, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { TextInput } from "react-native-web";
import ReportIssueDropDown from "./reportIssueComponents/ReportIssueDropDown";

const ReportIssue = ({ visible, onClose, onSubmit, programId }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    // Basic validation
    if (!selectedOption) {
      setError("Please select an issue type");
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const combinedComment = description.trim()
      ? `Issue Type: ${selectedOption}; Description: ${description.trim()}`
      : `Issue Type: ${selectedOption}`;

    const issueData = {
      user_id: 4000,//hardcoded for now
      program_id: programId,
      comment: combinedComment
    };
    console.log("Submitting issue data:", issueData);
    try {
      const response = await fetch("https://api.findgrant.ca/webpage/spot-mistake", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(issueData),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("Success:", data);

      // Reset form
      setSelectedOption(null);
      setDescription("");

      // Call the onSubmit prop to trigger the confirmation modal
      onSubmit(issueData);
      onClose();

    } catch (err) {
      console.error("Error submitting issue:", err);
      setError("Failed to submit issue. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const options = [
    "Inaccuracy in Grant Information",
    "Broken Link",
    "Outdated Information",
    "Technical Issue",
    "Other",
  ];

  return (
    <Modal visible={visible} transparent={true} animationType="fade">
      <View style={styles.overlay}>
        <View style={styles.reportIssueContainer}>
          <View style={styles.titleDescriptionContainer}>
            <Text style={styles.title}>Report an Issue</Text>
            <Text style={styles.description}>
              Your feedback is important! Please let us know what you'd like to
              report.
            </Text>
          </View>
          <View style={styles.selectContainer}>
            <Text style={styles.description}>Select issue</Text>
            <ReportIssueDropDown
              options={options}
              selectedValue={selectedOption}
              onSelect={setSelectedOption}
              placeholder="Select"
              maxHeight={1000}
            />
            {error && <Text style={styles.errorText}>{error}</Text>}
          </View>
          <View style={styles.textAreaContainer}>
            <Text style={styles.description}>
              Please describe the issue (optional)
            </Text>
            <TextInput
              editable
              multiline
              numberOfLines={5}
              style={styles.textarea}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <Pressable
              onPress={handleSubmit}
              style={[
                styles.submitButton,
                isSubmitting && styles.submitButtonDisabled
              ]}
              disabled={isSubmitting}
            >
              <Text style={styles.submitButtonText}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Text>
            </Pressable>
            <Pressable
              onPress={onClose}
              style={styles.cancelButton}
              disabled={isSubmitting}
            >
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ReportIssue;

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  reportIssueContainer: {
    width: 780,
    height: 570,
    padding: 20,
    backgroundColor: "white",
    borderRadius: 60,
    alignItems: "center",
  },
  cancelButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#FFF",
    borderColor: "#011F6F",
    borderWidth: 1,
    borderRadius: 50,
    width: 120,
    marginLeft: 10,
  },
  cancelButtonText: {
    fontSize: 16,
    color: "#011F6F",
    fontFamily: "OpenSans-Regular",
    textAlign: "center",
  },
  submitButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#011F6F",
    borderRadius: 50,
    width: 120,
  },
  submitButtonText: {
    color: "#fff",
    fontFamily: "OpenSans-Regular",
    fontSize: 16,
    textAlign: "center",
  },
  buttonsContainer: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 30
  },
  title: {
    fontSize: 24,
    color: "#011F6F",
    fontWeight: "600",
    fontFamily: "OpenSans-Semibold",
  },
  description: {
    fontSize: 16,
    textAlign: "left",
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "OpenSans-Regular",
  },
  titleDescriptionContainer: {
    width: "90%",
    marginTop: 20,
  },
  selectContainer: {
    width: "90%",
    marginTop: 20,
    zIndex: 2000,
    marginBottom: 30,
  },
  textAreaContainer: {
    width: "90%",
  },
  textarea: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 10,
    fontSize: 16,
    padding: 10,
  },
  errorText: {
    color: "red",
    fontSize: 14,
    marginTop: 5,
    fontFamily: "OpenSans-Regular",
  },
  submitButtonDisabled: {
    backgroundColor: "#667499",
  },
});
