import { Pressable, StyleSheet, Text } from "react-native";

const SearchButton = ({ onPress = () => {} }) => {
  return (
    <Pressable style={[styles.buttonSearch, { zIndex: 100 }]} onPress={onPress}>
      <Text style={styles.buttonTextSearch}>Search</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonSearch: {
    marginTop: 35,
    marginBottom: 40,
    paddingHorizontal: 80,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5BF4C",
    borderRadius: 30,
  },
  buttonTextSearch: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Arial",
  },
});

export default SearchButton;
