import React, { useState, useRef, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Dimensions,
} from "react-native";

const screenWidth = Dimensions.get("window").width;

const DropDown = ({ title, placeholder, items, style, children, onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef();

  const handleSearch = (text) => {
    setInputValue(text);
    const filteredData = items.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredItems(filteredData);
    setIsDropdownVisible(true); // Show dropdown when typing
  };

  const handleItemSelect = (option) => {
    setInputValue(option.label);
    setIsDropdownVisible(false); // Hide dropdown after selection

    //Propagate selected value to parent via onChange
    if (onChange) {
      onChange(option.label);
    }
  };

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Hide dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <View style={[styles.container, style]} ref={dropdownRef}>
      {/* Search and Select Input */}
      <View style={styles.placeholder}>
        <Text style={{ fontWeight: "bold" }}>{title}</Text>
        <TextInput
          value={inputValue}
          onChangeText={handleSearch}
          placeholder={placeholder}
          style={[styles.input, inputValue !== "" && styles.selectedText]}
          onFocus={() => setIsDropdownVisible(true)} // Show dropdown on focus
        />
      </View>

      {/* Dropdown list */}
      {isDropdownVisible && (
        <View style={[styles.dropdown, { zIndex: 800 }]}>
          <FlatList
            data={inputValue === "" ? items : filteredItems} //if input is empty, displays all options.
            keyExtractor={(option) => option.id.toString()}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.dropdownItem}
                onPress={() => handleItemSelect(item)}
              >
                <Text>{item.label}</Text>
              </TouchableOpacity>
            )}
          />
        </View>
      )}

      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: 220,
    height: 70,
  },
  placeholder: {
    paddingLeft: 25,
    paddingTop: 12,
  },
  input: {
    paddingTop: 5,
    fontSize: 13,
    color: "grey",
    outlineStyle: "none", //remove text input border
  },
  dropdown: {
    position: "absolute",
    top: 55,
    left:
      screenWidth < 950
        ? 220
        : screenWidth < 1261
        ? -175
        : screenWidth < 1080
        ? 40
        : 40,
    width: "80%",
    maxHeight: 250,
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  dropdownItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  selectedText: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default DropDown;
