import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, View, Text, StyleSheet, ScrollView } from 'react-native';
import Indigenous from '../../screens/ListGrantsTypes/IndigenousGrants';
import { Dimensions } from 'react-native';

// const dropdownMenu = [
//   { text: "Indigenous", link: "/Indigenous" },
//   { text: "Start-Up Grants", link: "/StartupGrants" },
//   { text: "Black Entrepreneurship Grants", link: "/BlackEntrepreneurshipGrants" },
//   { text: "Environmental Business Grants", link: "/EnvironmentalBusinessGrants" },
//   { text: "Technology & Innovation Grants", link: "/TechnologyInnovationGrants" },
//   { text: "Art & Culture", link: "/ArtCultureGrants" },
//   { text: "Small Business Grants", link: "/SmallBusinessGrants" },
//   { text: "Woman-Owned Business Grants", link: "/WomanOwnedBusinessGrants" },
// ];

const screenWidth = Dimensions.get('window').width

const dropdownMenu = [
  { text: "Indigenous", link: "Indigenous" },
  { text: "Start-Up Grants", link: "StartUp" },
  { text: "Black Entrepreneurship Grants", link: "BlackEntrepreneur" },
  { text: "Environmental Business Grants", link: "Environmental" },
  { text: "Technology & Innovation Grants", link: "Technology" },
  { text: "Art & Culture", link: "ArtCulture" },
  { text: "Small Business Grants", link: "SmallBusiness" },
  { text: "Woman-Owned Business Grants", link: "Women" },
];

const DropdownMenu = ({ onClose }) => {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {dropdownMenu.map((item, index) => (
          <Pressable
            key={index}
            style={styles.item}
            onPress={() => {
              console.log(`Selected: ${item.link}`);
              navigation.navigate(item.link); // Navigate to the item's link
              onClose(); // Close the dropdown
            }}
          >
            <Text style={styles.text}>{item.text}</Text>
          </Pressable>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {    
    padding: 5,
    backgroundColor: '#f9f9f9',
    borderRadius: 10,
    width: 270,
    maxHeight: 500,
    // top: screenWidth < 700  ? 32 : screenWidth < 821  ? 32: screenWidth < 1057  ? 0 : 0,
    // left: -5,
    position: "absolute",
  },
  scrollContainer: {
    flexGrow: 1,    
    marginTop: screenWidth < 700  ? 10 : screenWidth < 821  ? 10 : screenWidth < 1057  ? 0 : 0,
    marginBottom: 10,
  },
  item: {
    padding: 10,
  },
  text: {
    fontSize: 16,
    color: "black",
  },
});

export default DropdownMenu;
