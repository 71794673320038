import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const NavFooter = () => {
  return (
    <View style={styles.NavFooterContainer}>
      <Text style={styles.text}><a href="/AboutUs" style={styles.Link}>About Us</a></Text>
      <Text style={styles.text}><a href="/contactUs" style={styles.Link}>Contact Us</a></Text>
      <Text style={styles.text}><a href="/ProgramSearch" style={styles.Link}>Search Now</a></Text>
    </View>
  )
}

export default NavFooter

const styles = StyleSheet.create({
  text: {
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: 16,
    fontFamily: "OpenSans-Regular",
  },
  NavFooterContainer: {
    // width: 175,
    display: "flex",
    alignItems: "center",
    alignItems: "left",
    // paddingTop: 100
  },
  Link: {
    textDecorationLine: "none",
    color: "#FFFFFF"
  }
})