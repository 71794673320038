import { StyleSheet, Text, View, Image, Dimensions } from "react-native";
import React from "react";
import Step from "./Step";

const screenWidth = Dimensions.get("window").width;

const HowFindGrant = () => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>How FindGrant Works</Text>
      </View>
      <View style={styles.imageContainer}>
        <Step
          stepNumber="1"
          iconSource={require("../../assets/HowFindGrants/s1.png")}
          description="Use our website´s search bar to find grants tailored to your location, community, industry, and preferences."
          isLastStep={false}
          isTextFirst={true}
        />
        <Step
          stepNumber="2"
          iconSource={require("../../assets/HowFindGrants/s2.png")}
          description="Browse the results to find the grant that best fits your needs."
          isLastStep={false}
          isTextFirst={false}
        />
        <Step
          stepNumber="3"
          iconSource={require("../../assets/HowFindGrants/s3.png")}
          description="Review and select grants to apply."
          isLastStep={false}
          isTextFirst={true}
        />
        <Step
          stepNumber="4"
          iconSource={require("../../assets/HowFindGrants/s4.png")}
          description="Once approved, receive the fundings!"
          isLastStep={true}
          isTextFirst={false}
        />
      </View>
    </View>
  );
};

export default HowFindGrant;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    paddingTop: 90,
    paddingBottom: 40,
  },
  header: {
    marginBottom: 40,
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: screenWidth < 881  ? '3vh' : screenWidth < 1261  ? 48 : screenWidth < 1080  ? 48 : 48,
    color: "#011F6F",
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: screenWidth * 0.7,
    height: (screenWidth * 0.2 * 1534) / 1170,
    resizeMode: "contain",
    height: undefined,
    aspectRatio: 1170 / 1534,
    maxHeight: 1000,
  },
});
