//can be used for responsiveness


import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Image } from 'react-native-web';

const ScrollRightButton = ({ onPress }) => {
  return (
    <Pressable onPress={onPress} style={styles.rightArrowContainer}>
      <Image
        style={styles.rightArrow}
        source={require("../../../assets/searchPage/chevron.png")}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  rightArrowContainer: {
    width: 52,
    height: 52,
    borderRadius: 26,
    opacity: 0.9,
    backgroundColor: 'white',
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 7
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
    cursor: 'pointer',
  },
  rightArrow: {
    width: 24,
    height: 24,
    tintColor: "black",
  },
});

export default ScrollRightButton;