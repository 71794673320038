import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Circle, Defs, RadialGradient, Stop } from 'react-native-svg';


const GradientRadialCircle = ({ size, colors, style, uniqueId }) => {
  return (
    <View style={[{ width: size, height: size }, style]}>
      <Svg width={size} height={size}>
        <Defs>
          <RadialGradient id={`gradient-${uniqueId}`} cx="50%" cy="50%" r="50%" stylestyle={styles.gradientContainer}>
            <Stop offset="0%" stopColor={colors[0]} stopOpacity="1" />
            <Stop offset="100%" stopColor={colors[1]} stopOpacity="0" />
          </RadialGradient>
        </Defs>
        <Circle cx={size / 2} cy={size / 2} r={size / 2} fill={`url(#gradient-${uniqueId})`} />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  gradientContainer: {
    // position: 'relative',
    // overflow: 'hidden',
    // bottom: 0,
    // right: 0,
  },
});

export default GradientRadialCircle