import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Pressable,
  Dimensions,
} from "react-native";
import React, { useState } from "react";

//new
import axios from "axios";

const screenWidth = Dimensions.get("window").width;

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MsgError, setMsgError] = useState("");
  const [ifSuccess, setIfSuccess] = useState(false);
  const emailRegex = /^[a-zA-Z0-9_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmaiLChange = (text) => {
    setEmail(text);
    if (!emailRegex.test(email)) {
      setEmailError("Wrong Email format.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async () => {
    if (message.length === 0) {
      setMsgError("Message is required!");
    } else if (EmailError) {
      return;
    } else {
      setMsgError("");
      const data = {
        email: email,
        message: message,
      };

      try {
        const response = await axios.post(
          "https://api.trustnet.site/contact-us",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIfSuccess(true);
        console.log("Success:", response.data);
        alert("Form Submitted\nThank you for contacting us!");
        window.open("/", "_self");
        setEmail("");
        setMessage("");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <View style={styles.form}>
      <Text style={styles.title}>
        Got questions or need assistance? - We’re here to help
      </Text>
      <Text style={styles.title_sub}>
        Simply fill out the form below, and we'll get back to you as soon as
        possible.
      </Text>

      <View style={styles.form_fields_container}>
        <Text style={styles.label}>Email</Text>
        <TextInput
          style={styles.input}
          placeholder="Enter your Email"
          placeholderTextColor="grey"
          value={email}
          onChangeText={handleEmaiLChange}
        />
        <Text style={{ color: "red", fontSize: 16, position: "relative" }}>
          {EmailError}
        </Text>

        <Text style={styles.label}>Message</Text>
        <TextInput
          style={styles.input}
          placeholder="Write something.."
          placeholderTextColor="grey"
          multiline={true}
          numberOfLines={6}
          value={message}
          onChangeText={(input) => setMessage(input)}
        />
        <Text style={{ color: "red", fontSize: 16 }}>{MsgError}</Text>
        <Pressable title="Submit" onPress={handleSubmit}>
          <Text style={styles.submit}>Submit</Text>
          {/* Submit */}
        </Pressable>
        {ifSuccess && <Text>Thank you! We will be in touch.</Text>}
        <View style={{ paddingTop: 35, paddingBottom: 30 }}>
          <Text style={styles.content}>
            <Text style={styles.bold}>Address</Text>: 20 Dundas Street W,
            Toronto, Canada
          </Text>
          <Text style={styles.content}>
            <Text style={styles.bold}>Email</Text>: findgrantca [ @ ]
            gmail(.)com
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  form: {
    fontFamily: "OpenSans-Regular",
    width:
      screenWidth < 580
        ? "90%"
        : screenWidth < 881
        ? "90%"
        : screenWidth < 1080
        ? "85%"
        : screenWidth < 1343
        ? "80%"
        : "70%",
    borderRadius: 20,
    borderWidth: 1,
    backgroundColor: "#F7F7F7",
    minHeight: 500,
    margin: 20,
    marginBottom: 70,
    paddingVertical: 50,
    paddingHorizontal:
      screenWidth < 580
        ? 40
        : screenWidth < 881
        ? "10%"
        : screenWidth < 1080
        ? "10%"
        : screenWidth < 1343
        ? 120
        : 160,
  },
  bold: {
    fontWeight: "bold",
    color: "#000000",
  },
  content: {
    color: "grey",
    fontSize: 18,
  },
  form_fields_container: {
    width: "100%",
  },
  title: {
    fontSize: 34,
    marginTop: 20,
    textAlign: "center",
  },
  title_sub: {
    fontSize: 20,
    color: "grey",
    marginTop: 30,
    textAlign: "center",
  },
  label: {
    fontSize: 20,
    color: "grey",
    marginTop: 50,
    padding: 10,
  },

  input: {
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 20,
    marginBottom: 15,
    borderRadius: 5,
    backgroundColor: "#FFF",
  },
  submit: {
    borderRadius: 25,
    marginTop: 20,
    textAlign: "center",
    width: 200,
    paddingHorizontal: 14,
    paddingVertical: 15,
    color: "#FFFFFF",
    fontWeight: "bold",
    backgroundColor: "#5363f9",
    alignSelf: "center",
  },
});

export default ContactForm;
