import { Pressable, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { Image, ScrollView } from 'react-native-web';

const ReportIssueDropDown = ({
  options,
  selectedValue,
  onSelect,
  placeholder = "Select an option",
  maxHeight = 700
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={[styles.header, isOpen && styles.headerOpen]}
        onPress={toggleDropdown}
      >
        <Text style={styles.headerText}>
          {selectedValue || placeholder}
        </Text>
        <View>
          <Image
            style={[
              styles.arrow,
              isOpen && styles.arrowUp,
              { height: 20, width: 20 }
            ]}
            source={require("../../../assets/down-chevron.png")}
          />
        </View>
      </Pressable>

      {isOpen && (
        <View style={styles.dropdownOverlay}>
          <ScrollView
            style={[styles.optionsList, { maxHeight }]}
            showsVerticalScrollIndicator={true}
          >
            {options.map((option, index) => (
              <Pressable
                key={index}
                style={[
                  styles.option,
                  selectedValue === option && styles.selectedOption
                ]}
                onPress={() => handleSelect(option)}
              >
                <Text
                  style={[
                    styles.optionText,
                    selectedValue === option && styles.selectedOptionText
                  ]}
                >
                  {option}
                </Text>
              </Pressable>
            ))}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100%',
    zIndex: 1000,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    fontFamily: "OpenSans-Regular",
  },
  headerOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  headerText: {
    fontSize: 16,
    color: '#333',
    fontFamily: "OpenSans-Regular",
  },
  arrow: {
    color: '#666',
  },
  arrowUp: {
    transform: [{ rotate: '180deg' }],
  },
  dropdownOverlay: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#ccc',
    borderTopWidth: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 1001,
  },
  optionsList: {
    width: '100%',
  },
  option: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  selectedOption: {
    backgroundColor: '#f0f0f0',
  },
  optionText: {
    fontSize: 16,
    color: '#333',
    fontFamily: "OpenSans-Regular",
  },
  selectedOptionText: {
    color: '#011F6F',
  },
});

export default ReportIssueDropDown;