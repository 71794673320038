import { View, Text, Image, StyleSheet, Dimensions } from "react-native";

//import components
import Feature from "./WhyFindComponent/feature";

//Load Icons
const icon_money = require("../../assets/money.svg");
const icon_target = require("../../assets/target.svg");
const icon_rating = require("../../assets/rating.svg");

const screenWidth = Dimensions.get("window").width;

const WhyFindGrant = () => {
  return (
    <View style={styles.container}>
      {/* header */}
      <Text style={styles.header}>Why FindGrant?</Text>
      <View style={styles.boxContainer}>
        <Feature
          image={icon_money}
          title="Fast Result"
          description="Quickly find relevant grants with our advanced AI matching algorithm."
        />

        <Feature
          image={icon_target}
          title="Personalised"
          description="Receive personalised grant options based on your specific business needs."
        />

        <Feature
          image={icon_rating}
          title="Expertise"
          description="Quickly find relevant grants with our advanced AI matching algorithm."
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    borderColor: "black",
    margin: 10,
  },
  header: {
    fontWeight: "bold",
    fontSize: screenWidth < 881  ? '3vh' : screenWidth < 1261  ? 32 : screenWidth < 1080  ? 32 : 32,
    paddingBottom: 50,
    color: "#011F6F",
  },
  boxContainer: {
    flexDirection: "row",
    flexWrap: "wrap", // Allow boxes to wrap to the next line
    gap: 50,
    justifyContent: "center",
  },
});
export default WhyFindGrant;
