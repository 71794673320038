import React from 'react';
import { Svg, Circle, Defs, RadialGradient, Stop } from 'react-native-svg';

const FadedCircle = ({ radius, color, style, fadeWidth }) => {

  return (
    <Svg height={radius * 2} width={radius * 2} style={[style]}>
      <Defs>
        <RadialGradient
          id="grad"
          cx="50%" cy="50%" r="50%"
          fx="50%" fy="50%"
        >
          
              <Stop offset={`${(60 - fadeWidth)}%`} stopColor={color} stopOpacity= "0.9" />
              <Stop offset="100%" stopColor={color} stopOpacity="0" />
            
        
            
          
        </RadialGradient>
      </Defs>
      <Circle
        cx={radius}
        cy={radius}
        r={radius}
        fill="url(#grad)"
      />
    </Svg>
  );
};

export default FadedCircle;
