import { StyleSheet, View } from "react-native";
import React from "react";
import LogoFooter from "./footerComponents/LogoFooter";
import NavFooter from "./footerComponents/NavFooter";
import DropdownFooter from "./footerComponents/DropdownFooter";
import EmailFooter from "./footerComponents/EmailFooter";
import FooterVersion from "./footerComponents/FooterVersion";

const Footer = () => {
  return (
    <>
    <View>
    </View>
    <View style={styles.footerContainer}>
      <LogoFooter />
      <NavFooter />
      <DropdownFooter />
      <EmailFooter />
    </View>
    <FooterVersion />
    </>

  );
};

export default Footer;

const styles = StyleSheet.create({
  footerContainer: {
    backgroundColor: "#011F6F",
    // height: 518,
    width: '75%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingTop: 90,
    paddingHorizontal: 20,
    paddingBottom: 50,
    alignItems: "baseline",
    width: "100%",
    flexWrap: "wrap", // Allow boxes to wrap to the next line
    gap: 20,
  },
});
