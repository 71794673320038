import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, View, Text, StyleSheet, ScrollView } from 'react-native';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width

const dropdownMenu = [
  { text: "5 Mistakes to Avoid When Applying for Canadian Grants", link: "FiveMistakes" },
  { text: "Education Grants in Canada: Opportunities for Students and Educators", link: "EducationGrants" },
  { text: "Environmental Business Grants: How to Fund Your Green Business in Canada", link: "EnvironmentalBusinessGrants" },
  { text: "Essential Start-Up Grants for Canadian Entrepreneurs in 2024", link: "StartupGrantsCanada2024" },
  { text: "Grants vs. Loans in Canada: Which Financial Option is Right for You?", link: "GrantsVsLoans" },
  { text: "How Black Entrepreneurs Can Access Grants to Build Thriving Businesses", link: "BlackEntrepreneursGrants" },
  { text: "How to Find Grants for Non-Profit Organizations in Canada", link: "HowToFind" },
  { text: "Small Business Grants in Canada: What You Need to Know", link: "SmallBusinessGrantsPost" },
  { text: "Step-by-Step Guide to Applying for Grants in Canada", link: "GrantApplicationGuide" },
//   { text: "Essential Start-Up Grants for Canadian Entrepreneurs in 2024", link: "EssentialStartUp" },
//   { text: "Grants vs. Loans in Canada: Which Financial Option is Right for You?", link: "GrantsVsLoans" },
//   { text: "How Black Entrepreneurs Can Access Grants to Build Thriving Businesses", link: "BlackEntrepreneurs" },
//   { text: "How to Find Grants for Non-Profit Organizations in Canada", link: "FindGrants" },
//   { text: "Small Business Grants in Canada: What You Need to Know", link: "SmallBusinessGrants" },
//   { text: "Step-by-Step Guide to Applying for Grants in Canada", link: "StepByStep" },
  { text: "Top Funding Sources for Canadian Startups and Entrepreneurs", link: "TopFundingSources" },
  { text: "Top Government Grants Available for Canadians in 2024", link: "TopGovernmentGrants" },
  { text: "Top Indigenous Grants in Canada to Support Business and Community Growth", link: "TopIndigenousGrants" },
  { text: "Top Resources for Finding Grants in Each Canadian Province", link: "TopResources" },
  { text: "Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada", link: "WomenInBusiness" },
];



export default function Blog ({ onClose }) {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {dropdownMenu.map((item, index) => (
          <Pressable
            key={index}
            style={styles.item}
            onPress={() => {
              console.log(`Selected: ${item.link}`);
              navigation.navigate(item.link); // Navigate to the item's link
              onClose(); // Close the dropdown
            }}
          >
            <Text style={styles.text}>{item.text}</Text>
          </Pressable>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 5,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    width: screenWidth < 780 ? 300 : "35vw",
    maxHeight: 500,
    top: screenWidth < 700  ? 45 : screenWidth < 821  ? 32: screenWidth < 930  ? 80 :0,
    left: -20,
    position: "absolute",
  },
  scrollContainer: {
    flexGrow: 1,
  
  },
  item: {
    padding: 10,
  },
  text: {
    fontSize: 16,
    color: "black",
  },
});