import { Pressable, StyleSheet, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import DropDown from "../components/SearchBarComponents/DropdownComponent";
import ListGrants from "../components/ListGrants";
import { Image, View } from "react-native-web";
import SearchBarForSearchProgram from "../components/SearchProgram/SearchBarForSearchProgram";
import GrantOptionsIcons from "../components/GrantOptionsIcons";
import ExactMatch from "../components/ExactMatch";
import { useRoute } from "@react-navigation/native";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Header from "../components/Header.jsx";

const screenWidth = Dimensions.get("window").width;

const ProgramSearch = () => {
  const route = useRoute(); // Hook to access parameters
  const { selectedValues = {} } = route.params || {}; // Selected data from SearchBar
  console.log("Received selected values:", selectedValues);
  const [dropdownData, setDropdownData] = useState({
    location: [],
    community: [],
    industry: [],
    grants: [],
  });

  const [match, setMatch] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);

  // const [selectedFields, setSelectedFields] = useState(selectedValues);
  const [selectedFilters, setSelectedFilters] = useState({
    location: selectedValues.location || "",
    community: selectedValues.community || "",
    industry: selectedValues.industry || "",
    grants: selectedValues.grants || "",
  });

  const [filteredGrants, setFilteredGrants] = useState([]);

  // Function to load questions from the API
  const fetchQuestions = async () => {
    try {
      const response = await fetch(
        "https://api.findgrant.ca/webpage/load-questions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      // Map questions and save options to state
      const mappedData = data.reduce((acc, question) => {
        if (question.question === "My location is...") {
          acc.location = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am a...") {
          acc.community = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "My industry is...") {
          acc.industry = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am looking for...") {
          acc.grants = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        }
        return acc;
      }, {});

      setDropdownData(mappedData); // Save data in state
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchFilteredGrants = async (filters, searchTerm) => {
    try {
      const formData = new URLSearchParams();

      if (filters.location) formData.append("location", filters.location);
      if (filters.community) formData.append("ethnicity", filters.community);
      if (filters.industry) formData.append("industry", filters.industry);
      if (filters.grants) formData.append("program_target", filters.grants);
      formData.append("search_term", searchTerm);

      const response = await fetch(
        "https://api.findgrant.ca/webpage/form-submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      const data = await response.json();

      setFilteredGrants(data.program_result || []);
    } catch (error) {
      console.error("Error fetching filtered grants:", error);
    }
  };

  // const handleFilterChange = async (field, value) => {
  //   const updatedFilters = { ...selectedFilters, [field]: value };
  //   setSelectedFilters(updatedFilters);
  //   console.log( "Filters updated", updatedFilters)
  //   await fetchFilteredGrants(updatedFilters);
  // };

  const handleFilterChange = async (selectedFilter) => {
    const updatedFilters = { ...selectedFilters, ...selectedFilter };
    setSelectedFilters(updatedFilters);
    console.log( "Filters updated", updatedFilters)
    await fetchFilteredGrants(updatedFilters);
  };

  // const handlePress = (searchTerm) => {
  //   fetchFilteredGrants(searchTerm);
  // };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchFilteredGrants(selectedFilters);
  }, []);

  return (
    <>
      <Layout>
        <Header />
          <View style={styles.main}>
            <View style={styles.try}>
              {/* <View style={styles.try}>             */}
                {screenWidth < 881 ? (
                    <View style={styles.container}>
                      <View style={[styles.searchContainer, { zIndex: 200 }]}>
                        {/* <DropDown/> */}
                        <View style={[styles.dropdown, { zIndex: 500 }]}>
                          <SearchBarForSearchProgram
                            style={{ borderRadius: 35 }}
                            title="Location"
                            placeholder="Select your current location"
                            items={dropdownData.location}
                            defaultValue={selectedValues.location} //
                            onChange={(value) => handleFilterChange("location", value)}
                          ></SearchBarForSearchProgram>

                                <SearchBarForSearchProgram
                                  title="Community"
                                  placeholder="Select your community group"
                                  items={dropdownData.community}
                                  defaultValue={selectedValues.community}
                                  onChange={(value) => handleFilterChange("community", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>

                                <SearchBarForSearchProgram
                                  title="Industry"
                                  placeholder="Select your industry"
                                  items={dropdownData.industry}
                                  defaultValue={selectedValues.industry}
                                  onChange={(value) => handleFilterChange("industry", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>

                                <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Kind of Grant"
                                  placeholder="kind of grant you are looking for"
                                  items={dropdownData.grants}
                                  defaultValue={selectedValues.grants}
                                  onChange={(value) => handleFilterChange("grants", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>
                              </View>
                              <View style={styles.searchButtonContainer}>
                                <Pressable style={styles.searchButton}
                                  onPress={() => fetchFilteredGrants(selectedFilters)}
                                  >
                                  <Image
                                    style={styles.searchImage}
                                    source={require("../../assets/searchPage/search.svg")}
                                  />
                                </Pressable>
                              </View>
                            </View>
                          </View>
                        ) : screenWidth < 1261 ? (
                          <View style={styles.container}>
                            <View style={[styles.searchContainer, { zIndex: 200 }]}>
                              {/* <DropDown/> */}
                              <View style={[styles.dropdown, { zIndex: 500 }]}>
                                <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Location"
                                  placeholder="Select your current location"
                                  items={dropdownData.location}
                                  defaultValue={selectedValues.location} //
                                  onChange={(value) => handleFilterChange("location", value)}
                                ></SearchBarForSearchProgram>

                                <SearchBarForSearchProgram
                                  title="Community"
                                  placeholder="Select your community group"
                                  items={dropdownData.community}
                                  defaultValue={selectedValues.community}
                                  onChange={(value) => handleFilterChange("community", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>

                                {/* <SearchBarForSearchProgram
                                  title="Industry"
                                  placeholder="Select your industry"
                                  items={dropdownData.industry}
                                  defaultValue={selectedValues.industry}
                                >
                                  <View style={styles.halfLeftBorder} />
                                </SearchBarForSearchProgram> */}

                                {/* <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Kind of Grant"
                                  placeholder="kind of grant you are looking for"
                                  items={dropdownData.grants}
                                  defaultValue={selectedValues.grants}
                                >
                                  <View style={styles.halfLeftBorder} />
                                </SearchBarForSearchProgram> */}
                              </View>

                              <View style={[styles.dropdown, { zIndex: 500 }]}>
                                {/* <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Location"
                                  placeholder="Select your current location"
                                  items={dropdownData.location}
                                  defaultValue={selectedValues.location} //
                                ></SearchBarForSearchProgram> */}

                                {/* <SearchBarForSearchProgram
                                  title="Community"
                                  placeholder="Select your community group"
                                  items={dropdownData.community}
                                  defaultValue={selectedValues.community}
                                >
                                  <View style={styles.halfLeftBorder} />
                                </SearchBarForSearchProgram> */}

                                <SearchBarForSearchProgram
                                  title="Industry"
                                  placeholder="Select your industry"
                                  items={dropdownData.industry}
                                  defaultValue={selectedValues.industry}
                                  onChange={(value) => handleFilterChange("industry", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>

                                <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Kind of Grant"
                                  placeholder="kind of grant you are looking for"
                                  items={dropdownData.grants}
                                  defaultValue={selectedValues.grants}
                                  onChange={(value) => handleFilterChange("grants", value)}
                                >
                                  {/* <View style={styles.halfLeftBorder} /> */}
                                </SearchBarForSearchProgram>
                              </View>

                              <View style={styles.searchButtonContainer}>
                                <Pressable 
                                  style={styles.searchButton}
                                  onPress={() => fetchFilteredGrants(selectedFilters)}
                                >
                                  <Image
                                    style={styles.searchImage}
                                    source={require("../../assets/searchPage/search.svg")}
                                  />
                                </Pressable>
                              </View>
                            </View>
                          </View>
                        ) : (
                          <View style={[styles.container, {zIndex:150}]}>
                            <View style={[styles.searchContainer, { zIndex: 200 }]}>
                              {/* <DropDown/> */}
                              <View style={[styles.dropdown, { zIndex: 500 }]}>
                                <SearchBarForSearchProgram
                                  style={{ borderRadius: 35 }}
                                  title="Location"
                                  placeholder="Select your current location"
                                  items={dropdownData.location}
                                  defaultValue={selectedValues.location} //
                                  onChange={(value) => handleFilterChange("location", value)}
                            ></SearchBarForSearchProgram>

                            <SearchBarForSearchProgram
                              title="Community"
                              placeholder="Select your community group"
                              items={dropdownData.community}
                              defaultValue={selectedValues.community}
                              onChange={(value) => handleFilterChange("community", value)}
                            >
                              <View style={styles.halfLeftBorder} />
                            </SearchBarForSearchProgram>

                            <SearchBarForSearchProgram
                              title="Industry"
                              placeholder="Select your industry"
                              items={dropdownData.industry}
                              defaultValue={selectedValues.industry}
                              onChange={(value) => handleFilterChange("industry", value)}
                            >
                              <View style={styles.halfLeftBorder} />
                            </SearchBarForSearchProgram>

                            <SearchBarForSearchProgram
                              style={{ borderRadius: 35 }}
                              title="Kind of Grant"
                              placeholder="kind of grant you are looking for"
                              items={dropdownData.grants}
                              defaultValue={selectedValues.grants}
                              onChange={(value) => handleFilterChange("grants", value)}
                            >
                              <View style={styles.halfLeftBorder} />
                            </SearchBarForSearchProgram>
                          </View>
                          <View style={styles.searchButtonContainer}>
                            <Pressable 
                              style={styles.searchButton}
                              onPress={() => fetchFilteredGrants(selectedFilters)}
                            >
                              <Image
                                style={styles.searchImage}
                                source={require("../../assets/searchPage/search.svg")}
                              />
                            </Pressable>
                          </View>
                        </View>
                      </View>)}
                      <View style={styles.grantOptions}>
                        <View style={styles.dividerUp}/>
                        <View style={styles.grantOptionsIconsContainer}>
                          <ExactMatch
                            setActiveIcon={setActiveIcon}
                            match={match}
                            setMatch={setMatch}
                            activeIcon={activeIcon}
                          />
                          <View style={styles.dividerMiddle}/>
                          <GrantOptionsIcons
                            setActiveIcon={setActiveIcon}
                            activeIcon={activeIcon}
                            handlePress={handleFilterChange} // Pass this handler down
                          />
                        </View>
                        <View style={styles.dividerDown}/>
                      </View>
                  <View>
                    <ListGrants data={filteredGrants} />
                  </View>
              </View>
            {/* </View> */}
          </View>
        <Footer />
      </Layout>
    </>
  );
};

export default ProgramSearch;

const styles = StyleSheet.create({
  main: {
    alignItems: "center",
    flexWrap: "wrap",
    paddingHorizontal: 20,
    marginHorizontal: 20,
  },
  try: {
    width: "100%",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    marginVertical: 100,
    alignItems: "center",
    width: screenWidth < 700 ? "80%" : null,
    alignSelf: "center",
    // width: 1084,
    // height: 100,
    borderRadius: 50,
    paddingRight: 40,
    paddingLeft: 40,
    // backgroundColor: "#316EE5",
    opacity: "90%",
    borderColor: "black",
    borderColor: "rgba(0, 0, 0, 0.1)", // Light border color
    borderWidth: 2, // Thin border
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 5 }, // Shadow position
    shadowOpacity: 0.6, // Shadow transparency
    shadowRadius: 10, // Blurred shadow effect
  },
  circleWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 1,
  },
  LargeFont: {
    fontSize: 34,
    color: "white",
  },
  SmallFont: {
    fontSize: 12,
    textAlign: "center",
    color: "white",
  },

  searchContainer: {
    gap: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 35,
    // flexDirection: "row",

    flexDirection:
    screenWidth < 881
      ? "column"
      : screenWidth < 1261
      ? "column"
      : screenWidth < 1080
      ? "row"
      : "row",
    flexWrap: "wrap",

    textAlign: "left",
  },
  halfLeftBorder: {
    position: "absolute",
    left: 0,
    top: "17.5%",
    height: "65%", // Half of the height
    width: 1, // Width of the border
    backgroundColor: "#ccc", // Border color
  },
  searchButton: {
    backgroundColor: "#011f6f",
    height: 50,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  searchImage: {
    tintColor: "#FFF",
  },

  grantOptions: {
    flexWrap: "wrap",
    
    // paddingHorizontal: 50,
    paddingRight: 20,
    // backgroundColor: 'blue',
    // marginRight: 50,
  },

  searchButtonContainer: {
    marginLeft: 10,

  },
  grantOptionsIconsContainer: {
    width: "100%",
    // flexDirection: "row",
    // margin: screenWidth < 650 ? null : 20,
    position: "relative",
    overflow: "visible",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dividerUp: {
    width: "100%",
    height: 2,
    backgroundColor: "#ebebeb",
    marginBottom: 20,
  },
  dividerMiddle:{
    width: screenWidth < 1100 ? "100%" : "30vh",
    height: 2,
    backgroundColor: "#ebfbeb",
    marginVertical: 20,
  },
  dividerDown: {
    width: "100%",
    height: 2,
    backgroundColor: "#ebebeb",
    marginVertical: 20,

    // marginLeft: 40,
  },
  filterContainer: {
    height: 160,
  },
});

// function ConvertToProperty(data) {
//   return data.split(";").map((data, index) => ({
//     id: index + 1,
//     label: data.trim(),
//   }));
// }
