import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import Layout from "../components/Layout.jsx";
import ContactForm from "../components/ContactFormComponents/ContactForm.jsx";
import HeroSimpleTitle from "../components/HeroSimpleTitle.jsx";

const Contact = () => {
  return (
    <Layout>
      <Header />
      <HeroSimpleTitle title="Contact Us" subtitle="Welcome to our Contact Us page, where reaching out to us is just a click away." />
      <ContactForm />
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
});

export default Contact;
