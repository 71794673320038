import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import EmailInput from './EmailInput'

const EmailFooter = () => {
  return (
    <View style={styles.emailFooterContainer}>
      <Text style={styles.textTitle}>Stay in the Know</Text>
      <Text style={styles.text}>Receive the latest Canadian government funding updates directly in your inbox</Text>
      <EmailInput />
    </View>
  )
}

export default EmailFooter

const styles = StyleSheet.create({
  text: {
    color: "white",
    fontSize: 16,
    fontWeight: 400,
    paddingBottom: 16,
    fontFamily: "OpenSans-Regular",
  },
  emailFooterContainer: {
    width: 360,
    display: "flex",
    alignItems: "center",
    alignItems: "left",
    // paddingTop: 100

  },
  textTitle: {
    color: "white",
    fontSize: 19,
    fontWeight: 700,
    paddingBottom: 16,
    fontFamily: "OpenSans-Regular",
  }
})