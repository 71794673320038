import {View,Image,Text, StyleSheet} from 'react-native';


const Feature=({image,title,description})=>{
    return(
        <View style={[styles.box]}>
            <Image source={image} style={[styles.Icon]}></Image>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
    )
}

const styles=StyleSheet.create({
    box:{ 

        width: 250,
        height: 300,
        borderRadius: 20, 
        alignItems: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        },
    title:{
            fontSize: 20,
            fontWeight: "bold",
            color: "#011F6F",
    },
    description:{
            fontFamily: "OpenSans-Regular",
            textAlign: "center",
            marginTop: 10,
            width: "65%",
    },
    Icon:{
            width: 80,
            height: 80,
            tintColor: "#011F6F",
            marginTop: 30,
            marginBottom: 10,
    }
})

export default Feature;